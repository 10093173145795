import React from 'react'
import produce from 'immer'
import { Toaster } from '@blueprintjs/core'
import { Panel, Row } from './components/flex'
import center from '@turf/center'
import { authorize, debug, getImageFromBase64 } from './common'
import orderBy from 'lodash.orderby'
import Style from './models/style'
import Loading from './components/loading'
import Map, { MapViewOptions } from './components/map'
import Sidebar from './components/sidebar'
import { FeatureCollection } from './models/feature'
import MapContext, { MapContextInterface } from './map_context'
import uuid from 'uuid/v4'
import Amenity from './models/amenity'
import AmenityCategory from './models/amenity_category'
import Place from './models/place'
import Floor from './models/floor'
import Feature from './models/feature'
import GeoJSONSource from './sources/geojson_source'
import RoutingSource, { RouteOptions } from './sources/routing_source'
import SyntheticSource from './sources/synthetic_source'
import ClusterSource from './sources/cluster_source'
import ImageSourceManager from './sources/image_source_manager'
import Repository from './repository'
import DataSource from './sources/data_source'
import amenityStore from './amenity_store'
import repository from './repository'
import notifier from './notifier'
import eventBus from './event_bus'
import Session from './session'
import Login from './components/login'
import { LANGUAGES } from './models/language'
import { User } from './types/user'
import { chevron, iconLeft, iconRight, route_a, route_b, disabled, workingHours, defaultPoi as poi } from './icons'
import { DrawMode } from './types/draw_mode'
import NoPlaces from './components/no_places'
import { DEFAULT_FONT } from './constants'
import { FilterDialog } from './components/filter.dialog'
import {AnyLayer} from "./layers/any_layer";

interface Props {

}

interface State {
  initializing: boolean
  loggedIn: boolean
  amenityCategories: AmenityCategory[]
  amenities: Amenity[]
  editables: FeatureCollection,
  floor: Floor
  floors: Floor[]
  mode: DrawMode
  place: Place
  places: Place[]
  style: Style
  styles: Style[]
  selected: Feature[]
  selectorFeatures: Feature[]
  latitude: number
  longitude: number
  loadingRoute: boolean
  options: MapViewOptions
  noPlaces: boolean
  inspect: boolean
  inspection: Feature[]
  showFilter: boolean
  usesMetadataFilter: boolean
}

const MAPBOX_TOKEN = ''

export default class Main extends React.Component<Props, State> {
  toasterRef = React.createRef<Toaster>()
  mapRef = React.createRef<Map>()
  session = new Session()

  originalStyle: any
  geojsonSource: GeoJSONSource = new GeoJSONSource()
  syntheticSource: SyntheticSource = new SyntheticSource(new FeatureCollection({}))
  routingSource: RoutingSource = new RoutingSource()
  clusterSource: ClusterSource = new ClusterSource()
  imageSourceManager: ImageSourceManager = new ImageSourceManager()

  constructor(props: Props) {
    super(props)
    this.state = {
      amenities: [],
      amenityCategories: [],
      editables: new FeatureCollection({}),
      loggedIn: false,
      initializing: true,
      inspect: false,
      floor: new Floor({}),
      floors: [],
      place: new Place({}),
      places: [],
      style: new Style({}),
      styles: [],
      selected: [],
      selectorFeatures: [],
      latitude: 60.1669635,
      longitude: 24.9217484,
      loadingRoute: false,
      noPlaces: false,
      mode: 'simple_select',
      options: {
        coordinates: [0, 0],
        zoom: 0,
        pitch: 0,
        bearing: 0,
        bounds: [[0, 0], [0, 0]]
      },
      inspection: [],
      showFilter: false,
      usesMetadataFilter: false
    }

    this.onPlaceSelect = this.onPlaceSelect.bind(this);
    this.onFloorSelect = this.onFloorSelect.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onMapReady = this.onMapReady.bind(this);
    this.onMapSelection = this.onMapSelection.bind(this);
    this.onBoxSelection = this.onBoxSelection.bind(this);
    this.onFeatureCreate = this.onFeatureCreate.bind(this);
    this.onFeatureUpdate = this.onFeatureUpdate.bind(this);
    this.onFeatureDelete = this.onFeatureDelete.bind(this);
    this.onCenterChange = this.onCenterChange.bind(this);
    this.onAmenityReload = this.onAmenityReload.bind(this);
    this.onAmenityStoreChange = this.onAmenityStoreChange.bind(this);
    this.onNotifierMessage = this.onNotifierMessage.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSyntheticChange = this.onSyntheticChange.bind(this);
    this.onStyleChange = this.onStyleChange.bind(this);
    this.onStyleSelect = this.onStyleSelect.bind(this);
    this.onRouteUpdate = this.onRouteUpdate.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
    this.onRouteCancel = this.onRouteCancel.bind(this);
    this.onDrawModeChange = this.onDrawModeChange.bind(this);
    this.onMigrate = this.onMigrate.bind(this);
    this.onFeatureQueryChange = this.onFeatureQueryChange.bind(this);
    this.onBusEvent = this.onBusEvent.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onOptionsChange = this.onOptionsChange.bind(this);
    this.onRasterToggle = this.onRasterToggle.bind(this);
    this.onInspect = this.onInspect.bind(this);
    this.submitGeoJSON = this.submitGeoJSON.bind(this);
    this.session.clear();

    const token = this.getParameterByName('token');
    if (token) {
      this.session.save(token)
    }
  }

  componentDidMount() {
    let win = window as any
    win.editor = this
    if (this.session.loggedIn) {
      void this.initialize()
    }
  }

  componentWillUnmount() {
    void this.cancelObservers()
  }

  getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  async initialize() {
    authorize(this.session.getToken())
    amenityStore.observe(this.onAmenityStoreChange)
    notifier.observe(this.onNotifierMessage)
    eventBus.observe(this.onBusEvent)
    this.geojsonSource.observe(this.onSourceChange)
    this.syntheticSource.observe(this.onSyntheticChange)
    this.routingSource.observe(this.onRouteChange)
    await Promise.all([
      amenityStore.initialize(),
      this.fetch()
    ]);

    (window as any).amenityStore = amenityStore;
  }

  async cancelObservers() {
    amenityStore.cancel(this.onAmenityStoreChange)
    notifier.cancel(this.onNotifierMessage)
    this.geojsonSource.cancel(this.onSourceChange)
    this.syntheticSource.cancel(this.onSyntheticChange)
    this.state.style.cancel(this.onStyleChange)
  }

  async centerInternalFeature(internalId: string) {
    const feature = this.geojsonSource.getInternal(internalId)
    const fCenter = center(feature as any)
    if (fCenter.geometry) {
      this.getMap()?.setCenter(fCenter.geometry.coordinates as any)
    }
  }

  async fetch() {
    const { places, style, styles } = await Repository.getPackage();
    await this.geojsonSource.fetch();
    await this.onSourceChange();
    this.prepareStyle(style);
    let place = places.length > 0 ? orderBy(places, ['name', 'asc'])[0] : new Place({});
    this.imageSourceManager.belowLayer = style.usesPrefixes() ? 'proximiio-floors' : 'floors';
    await this.imageSourceManager.initialize();

    this.setState({
      amenities: [...amenityStore.amenities.map(a => new Amenity(a))],
      amenityCategories: [...amenityStore.categories],
      initializing: false,
      place,
      places,
      style,
      styles,
      latitude: place.lat,
      longitude: place.lng,
      noPlaces: places.length === 0
    }, () => {
      style.observe(this.onStyleChange);
      this.onPlaceSelect(this.state.place)
    })
  }

  prepareStyle(style: Style) {
    style.setSource('main', this.geojsonSource)
    style.setSource('synthetic', this.syntheticSource)
    style.setSource('route', this.routingSource)
    style.setSource('clusters', this.clusterSource)
    style.setLevel(0)
  }

  async onRouteChange(event?: string, data?: any) {
    if (event === 'loading-start') {
      this.setState({ loadingRoute: true })
      return
    }

    if (event === 'loading-finished') {
      this.setState({ loadingRoute: false })
      return
    }

    if (event === 'options-changed') {
      this.routingSource.updateOptions(data);
      return
    }

    const style = this.state.style
    style.setSource('route', this.routingSource)
    this.setState({ style }, () => {
      this.updateMapSource(this.routingSource)
    })
  }

  async onAmenityStoreChange(event?: string, data?: any) {
    console.log('onAmenityStoreChange');

    this.setState({
      amenities: [...amenityStore.amenities.map(a => new Amenity(a))],
      amenityCategories: [...amenityStore.categories]
    }, async () => {
      const map = this.getMap();

      if (data) {
        const amenity = data as Amenity;

        if (event === 'add' && data) {
          const image = await getImageFromBase64(amenity.icon) as any;
          console.log('adding image', amenity);
          this.getMap()?.addImage(amenity.id, image);
        }

        if (event === 'update' && data) {
          const image = await getImageFromBase64(amenity.icon) as any;
          if (map?.hasImage(amenity.id)) {
            map?.removeImage(amenity.id);
            console.log('updating image', amenity);
            map?.addImage(amenity.id, image)
          }
        }

        if (event === 'delete' && data) {
          if (map?.hasImage(amenity.id)) {
            console.log('deleting image', amenity);
            map?.removeImage(amenity.id);
          }
        }
      }
    })
  }

  async onAmenityReload() {
    void amenityStore.fetch()
  }

  async onNotifierMessage(event?: string, message?: string) {
    if (!event || !message) {
      return
    }

    this.toast(message, event === 'failure')
  }

  async onSourceChange() {
    console.log('on source change');
    this.setState({
      selected: this.state.selected.map(feature => this.geojsonSource.get(feature.id) || this.geojsonSource.getInternal(feature.properties.id)).filter(f => f),
      style: this.state.style,
      usesMetadataFilter: this.geojsonSource.usesMetadataFilter
    }, () => {
      this.updateMapSource(this.geojsonSource);
      this.routingSource.routing.setData(this.geojsonSource.collection);
      this.updateCluster();
    })
  }

  async onSyntheticChange() {
    this.state.style.setSource('synthetic', this.syntheticSource)
    const draw = this.getDraw()
    const currentMode = draw.getMode();
    this.setState(produce(this.state, state => {
      state.selected = this.state.selected
        .map(feature => this.syntheticSource.get(feature.id))
        .filter(f => f)
      state.style = this.state.style
      state.mode = currentMode
    }), () => {
      draw.changeMode(currentMode);
      this.mapRef.current?.onDrawModeChange(currentMode);
      this.updateMapSource(this.syntheticSource);
    })
  }

  updateMapSource(source: DataSource) {
    const map = this.getMap()
    if (map) {
      const mapSource = map.getSource(source.id) as any
      if (mapSource) {
        mapSource.setData(source.data)
      }
    }
  }

  onStyleSelect(style: Style) {
    const map = this.getMap()
    if (map) {
      this.prepareStyle(style)
      map.setStyle(style.json)
    }

    this.setState({ style })
  }

  async onStyleChange(event?: string, data?: any) {
    const map = this.getMap()
    if (map) {
      if (event === 'polygon-editing-toggled') {
        if (this.state.style.polygonEditing) {
          if (!this.state.style.segments) {
            this.state.style.toggleSegments()
          }
          if (!this.state.style.routable) {
            this.state.style.toggleRoutable()
          }
        } else {
          if (this.state.style.overlay) {
            this.state.style.toggleOverlay()
          }
          if (this.state.style.segments) {
            this.state.style.toggleSegments()
          }
          if (this.state.style.routable) {
            this.state.style.toggleRoutable()
          }
        }
      }

      if (event === 'overlay-toggled') {
        const overlay = this.state.style.overlay ? 'visible' : 'none'
        map.setLayoutProperty('main-polygon-fill', 'visibility', overlay)
        map.setLayoutProperty('main-polygon-outline', 'visibility', overlay)
      }

      if (event === 'segments-toggled') {
        const segments = this.state.style.segments ? 'visible' : 'none'
        map.setLayoutProperty('main-segment-fill', 'visibility', segments)
        map.setLayoutProperty('main-segment-outline', 'visibility', segments)
      }

      if (event === 'routable-toggled') {
        const routables = this.state.style.segments ? 'visible' : 'none'
        map.setLayoutProperty('main-routable-fill', 'visibility', routables)
        map.setLayoutProperty('main-routable-outline', 'visibility', routables)
      }

      if (event === 'cluster-toggled') {
        const clusters = this.state.style.cluster ? 'visible' : 'none'
        map.setLayoutProperty('clusters-circle', 'visibility', clusters);
        map.setLayoutProperty('clusters-count', 'visibility', clusters);
      }

      if (event === 'fills-toggled') {
        const layers = this.state.style.getLayers('main').filter(l => l.type === 'fill' && l.id.substring(0, 4) !== 'main');
        layers.forEach(layer => {
          map.setLayoutProperty(layer.id, 'visibility', this.state.style.fills ? 'visible' : 'none')
        })
      }

      if (event === 'show-text-toggled') {

      }
    }

    if (event === 'layer-update' && data) {
      const { layer, changes }: any = data
      const layoutChanges = (changes as any[]).filter(diff => diff.kind === 'E' && diff.path[0] === 'layout')
      const paintChanges = (changes as any[]).filter(diff => diff.kind === 'E' && diff.path[0] === 'paint')
      const map = this.getMap()
      if (map) {
        layoutChanges.forEach(change => {
          if (change.kind === 'E') {
            map.setLayoutProperty(layer.id, change.path[1], change.rhs)
          }
        })
        paintChanges.forEach(change => {
          if (change.kind === 'E') {
            map.setPaintProperty(layer.id, change.path[1], change.rhs)
          }
        })
      }
    }

    this.setState({ style: this.state.style })
  }

  async onInspect(features: Feature[]) {
    this.setState({ inspection: features })
  }

  onRasterToggle(value: boolean) {
    this.imageSourceManager.enabled = value
    const map = this.getMap()
    if (map) {
      this.imageSourceManager.setLevel(map, this.state.floor.level)
    }
  }

  async onMapReady() {
    // set paths visible if available
    const map = this.getMap()
    if (map) {
      this.state.style.togglePaths(true)
      // routing layers
      let routingLayer = map.getLayer('routing-line-completed')
      let usePrefixed = typeof routingLayer === 'undefined' && typeof map.getLayer('proximiio-routing-line-completed') !== 'undefined';
      const shopsLayer = map.getLayer('shops')

      if (usePrefixed) {
        map.moveLayer('proximiio-routing-line-completed', 'proximiio-outer_wall');
        map.moveLayer('proximiio-routing-line-remaining', 'proximiio-outer_wall');
        if (routingLayer) {
          map.moveLayer('proximiio-paths', 'routing-line-completed');
        }
      } else {
        if (routingLayer) {
          const routingSymbolsLayer = map.getLayer('proximiio-routing-symbols');
          if (shopsLayer && routingSymbolsLayer) {
            map.moveLayer('routing-line-completed', 'proximiio-routing-symbols')
            map.moveLayer('routing-line-remaining', 'proximiio-routing-symbols')
          }
          if (routingLayer) {
            if (map.getLayer('paths')) {
              map.moveLayer('paths', 'routing-line-completed')
            }
          }
          map.moveLayer('main-poi-unavailable', 'proximiio-pois-icons');
        }
      }
      map.setMaxZoom(30)
      const decodedChevron = await getImageFromBase64(chevron);
      const rightIcon = await getImageFromBase64(iconRight);
      const leftIcon = await getImageFromBase64(iconLeft);
      const routeA = await getImageFromBase64(route_a);
      const routeB = await getImageFromBase64(route_b);
      const disabledIcon = await getImageFromBase64(disabled);
      const hours = await getImageFromBase64(workingHours);
      const defaultPoi = await getImageFromBase64(poi);

      map.addImage('chevron_right', decodedChevron as any);
      map.addImage('arrow_right', rightIcon as any);
      map.addImage('arrow_left', leftIcon as any);
      map.addImage('route_start', routeA as any);
      map.addImage('route_finish', routeB as any);
      map.addImage('disabled', disabledIcon as any);
      map.addImage('working-hours', hours as any);
      map.addImage('default-poi', defaultPoi as any);

      this.updateMapSource(this.geojsonSource);
      this.updateMapSource(this.routingSource);
      this.updateCluster();
      this.state.style.togglePolygonEditing();
      this.imageSourceManager.setLevel(map, this.state.floor.level);

      // overloadPaths() {
      // needed to remove the path layer defined in style
      // const layer = this.state.style.layers.find(layer => layer.id === 'proximiio-paths' || layer.id === 'paths')
      // if (layer) {
      //   console.log('removing layer', layer.id);
      //   map.removeLayer(layer.id);

      //   const _layer = this.state.style.layers.find(l => l.id === layer.id);
      //   if (!_layer) {
      //     return
      //   }

      //   if (_layer.filter) {
      //     layer.filter.push(
      //       "any",
      //       [
      //         "!",
      //         ["has", "narrowPath"]
      //       ],
      //       [
      //         "==",
      //         ["get", "narrowPath"],
      //         false
      //       ]
      //     ]);
      //     console.log('®eadding layer', _layer.json);
      //     map.addLayer(_layer.json);
      //     this.state.style.setLevel(this.state.floor.level)
      //   }
      // }
    }
  }

  updateCluster() {
    const map = this.getMap()
    if (map) {
      const data = {
        type: 'FeatureCollection',
        features: this.geojsonSource.data.features
                    .filter(f => f.isPoint && f.hasLevel(this.state.floor.level))
                    .map(f => f.json)
      } as FeatureCollection

      const source = map.getSource('clusters') as any
      if (source) {
        source.setData(data)
      }
    }
  }

  async onSave() {
    try {
      if (this.geojsonSource.deleted.length > 0) {
        await repository.deleteFeatures(this.geojsonSource.deleted)
      }
      if (this.geojsonSource.dirty.length > 0) {
        await repository.saveFeatures(this.geojsonSource.dirty)
      }
      this.geojsonSource.resetDirty();
      try {
        await repository.publishFeatures();
      } catch {
        // do nothing
      }
      this.toast("Data was successfully saved")
    } catch (e) {
      this.toast("An error occurred during data save", true)
    }
  }

  private onDateSelect = (date: Date | null) => {
    this.geojsonSource.setDateFilter(date);
    this.routingSource.setDateFilter(date);
  }

  // this.setState({
  //   date: date || new Date()
  // }, () => {
  //   console.log('should filter features to', this.state.date);
  // })

  getMap() {
    return this.mapRef.current?.map
  }

  getDraw() {
    return this.mapRef.current?.drawRef.current?.draw
  }

  migrateStairs() {
    const features = this.geojsonSource.data.features.filter(f => f.properties.type === 'staircase')
    features.forEach(f => {
      const feat = new Feature(f.json)
      feat.properties.amenity = 'staircase'
      this.geojsonSource.update(feat)
    })
  }

  onMigrate() {
    // const lineStrings = ['LineString', 'MultiLineString']
    // const polygonStrings = ['Polygon', 'MultiPolygon']
    const points = this.geojsonSource.data.features.filter(f => f.isPoint)
    // const lines = this.geojsonSource.data.features.filter(f => lineStrings.includes(f.geometry.type))
    // const polygons = this.geojsonSource.data.features.filter(f => polygonStrings.includes(f.geometry.type))

    const newPoints = points.map(point => {
      const f = point.json
      if (typeof f.properties.title === 'string') {
        f.properties.title_i18n = {
          en: f.properties.title
        }
        delete f.properties.title
      }

      if (f.properties.type === 'text') {
        if (Array.isArray(f.properties.textFont)) {
          f.properties.textFont = f.properties.textFont[0]
        }

        if (typeof f.properties.textFont === 'undefined') {
          f.properties.textFont = DEFAULT_FONT
        }

        if (typeof f.properties.textSize === 'undefined') {
          f.properties.textSize = 14
        }
      }

      if (f.properties.usecase && f.properties.usecase === 'levelchanger') {
        f.properties.levels = []
        for (let i = f.properties.level_min; i <= f.properties.level_max; i++) {
          f.properties.levels.push(i)
        }
        f.properties.type = f.properties.levelchanger
        delete f.properties.levelchanger
        delete f.properties.level_min
        delete f.properties.level_max
      } else {
        f.properties.type = 'poi'
      }

      delete f.properties.usecase
      return new Feature(f)
    })

    newPoints.forEach(point => this.geojsonSource.update(point))
    this.toast("Migration completed, data prepared for saving")
  }

  onBusEvent(event?: string, data?: any) {
    debug.log('on bus event', event, data)
  }

  async submitGeoJSON(data: string) {
    try {
      const collection = JSON.parse(data) as FeatureCollection
      collection.features.forEach(feature => {
        this.geojsonSource.create(feature)
      })
      this.toast("FeatureCollection was successfully processed")
    } catch (e) {
      this.toast("FeatureCollection processing error", true)
    }
  }

  toast(message: string, isError: boolean = false) {
    this.toasterRef.current?.show({
      message,
      intent: isError ? 'danger' : 'success'
    })
  }

  async onFloorSelect(floor: Floor) {
    const map = this.getMap()
    if (map) {
      this.state.style.setLevel(floor.level);

      if (map.getLayer('simple-tiles-layer')) {
        map.removeLayer('simple-tiles-layer');
        map.removeSource('simple-tiles-source');
        map.addSource('simple-tiles-source', {
          type: 'raster',
          tiles: [ this.state.style.getTilesUrl(floor.level) ],
          tileSize: this.state.style.metadata['proximiio:raster:tilesize'] || 256
        });
        map.addLayer({
          id: 'simple-tiles-layer',
          type: 'raster',
          source: 'simple-tiles-source'
        });
        map.moveLayer('simple-tiles-layer', 'proximiio-floors');
      }

      [...this.state.style.getLayers('main'), ...this.state.style.getLayers('route')]
        .forEach(layer => map.setFilter(layer.id, layer.filter))
      this.imageSourceManager.setLevel(map, floor.level)
    }

    this.setState({ floor, style: this.state.style }, () => {
      this.updateCluster()
    })
  }

  async onPlaceSelect(place: Place) {
    const floors = await Repository.getFloors(0, place.id)
    this.setState({ place }, () => {
      const state: any = { floors: orderBy(floors, ['level', 'asc']) }

      if (floors.length > 0) {
        const groundFloor = floors.find(floor => floor.level === 0)
        if (groundFloor) {
          state.floor = groundFloor
        } else {
          state.floor = floors[0]
        }
      }

      this.setState(state, () => {
        const map = this.getMap()
        if (map) {
          map.flyTo({ center: [ place.lng, place.lat ] })
        }
      })
    })
  }

  async onMapSelection(_features: Array<Feature>) {
    const features = _features.map(feature =>
      this.syntheticSource.get(feature.properties.id) ||
      this.geojsonSource.get(feature.id) ||
      this.geojsonSource.getInternal(feature.properties.id)
    ).filter(f => f);

    debug.log('[Main.onMapSelection]', features);

    const state: any = { selected: features };
    const draw = this.getDraw();

    if (draw) {
      draw.deleteAll();

      if (_features.length > 0) {
        const editable = _features.find(feature => feature.isEditable);
        if (editable) {
          const feats = draw.set(new FeatureCollection({ features: features }).json);
          const currentMode = this.getDraw().getMode();
          draw.changeMode('simple_select', { featureIds: feats });
          if (this.state.mode === 'draw_line_string' && currentMode === 'simple_select') {
            // in this case, switch to simple_select and then back to draw_line_string to keep drawing new line
            this.mapRef.current?.onDrawModeChange('draw_line_string');
          }
          // this.mapRef.current?.setState({ mode: 'simple_select' })
        } else {
          //const features = _features.map(f => this.geojsonSource.get(f.id) || this.geojsonSource.getInternal(f.properties.id))
        }
      }
    }

    this.setState(state)
  }

  async onBoxSelection(polygon: Feature) {
    debug.log('polygon selected', polygon);
  }

  async onDrawModeChange(mode: DrawMode) {
    this.setState({ mode }, () => {
    // , () => {
      if (mode === 'simple_select') {
        this.getDraw().changeMode(mode, { featureIds: [] })
      }
    })
  }

  async onFeatureCreate(feature: Feature, synthetic = true) {
    debug.log('on feature create', feature)

    if (this.state.place.exists) {
      feature.properties.place_id = this.state.place.id
    }

    if (this.state.floor.exists) {
      feature.properties.floor_id = this.state.floor.id
      feature.properties.level = this.state.floor.level
    } else {
      feature.properties.level = 0
    }

    feature.id = uuid()
    feature.properties.id = feature.id

    if (feature.properties.title_i18n) {
      this.geojsonSource.mapFeatureLanguage(feature, 'en')
    }

    this.syntheticSource.create(feature)
    const modeFrom = this.getDraw().getMode()

    if (!synthetic) {
      const f = await this.onFeatureUpdate(feature)
      this.onMapSelection([f])
      if (this.getDraw()) {
        this.getDraw().changeMode(modeFrom)
      }
    }
  }

  async onFeatureUpdate(_feature: Feature, geometryOnly = false) {
    debug.log('on feature update', _feature, 'is synthetic', _feature.isSynthetic, 'geometryOnly', geometryOnly, 'isInstanceOfFeature', _feature instanceof Feature);
    // ensure Feature class, draw plugins returns its own Feature object
    const feature = _feature instanceof Feature ? _feature : new Feature(_feature)

    if (!feature.id && feature.properties.id) {
      feature.id = feature.properties.id
    }

    if (feature.isSynthetic) {
      console.log('feature is synthetic');
      this.syntheticSource.update(feature)
      return feature
    }

    if (feature.isRouting) {
      if (feature.properties.amenity === 'route_start') {
        await this.routingSource.update(feature, this.routingSource.finish)
      } else {
        await this.routingSource.update(this.routingSource.start, feature)
      }

      this.onMapSelection([ feature ])
      return feature
    }

    // synthetic => data crossing
    if (!(this.geojsonSource.get(feature.id) || this.geojsonSource.getInternal(feature.properties.id))) {
      console.log('removing from synthetic, creating in geojson');
      this.syntheticSource.delete(feature)
      this.geojsonSource.create(feature)
    } else {
      if (geometryOnly) {
        const existing = this.geojsonSource.get(feature.id) || this.geojsonSource.getInternal(feature.properties.id);
        existing.geometry = feature.geometry;
        debug.log('geometryOnly update', existing);
        this.geojsonSource.update(existing)
      } else {
        console.log('updating geojson source');
        this.geojsonSource.update(feature)
      }
    }

    return feature
  }

  async onFeatureDelete(feature: Feature) {
    debug.log('on featuredelete', feature);
    if (feature.isSynthetic) {
      this.syntheticSource.delete(feature)
    } else {
      this.geojsonSource.delete(feature)
    }
  }

  onCenterChange(center: [number, number]) {
    // this.setState({
    //   latitude: center[1],
    //   longitude: center[0]
    // })
    // debug.log('center changed')
  }



  async onRouteUpdate(start?: Feature, finish?: Feature) {
    try {
      await this.routingSource.update(start, finish)
    } catch (e) {
      debug.error('catched', e)
    }
    this.setState({ style: this.state.style })
  }

  onRouteCancel() {
    this.routingSource.cancel()
  }

  onFeatureQueryChange(query: string) {
    if (query.length > 2) {
      this.setState({ selectorFeatures: this.geojsonSource.query(query) })
    } else {
      this.setState({ selectorFeatures: [ ] })
    }
  }

  onOptionsChange(options: MapViewOptions) {
    this.setState({ options })
  }

  onLogin(user: User) {
    this.session.save(user.token);
    this.initialize()
  }

  onLogout() {
    this.session.clear();
    window.location.pathname = '/'
  }

  onRouteOptionsChange = async (options: RouteOptions) => {
    this.routingSource.updateOptions(options);
    await this.routingSource.update(this.routingSource.start, this.routingSource.finish)
  }

  onFilterClick = () => {
    this.setState({ showFilter: true })
  }

  private onFilterClose = () => {
    this.setState({ showFilter: false })
  }

  render() {
    if (!this.session.loggedIn) {
      return <Login onLogin={this.onLogin}/>
    }

    if (!this.session.loggedIn || this.state.initializing) {
      return <Loading />
    }

    const context: MapContextInterface = {
      amenities: this.state.amenities,
      amenityCategories: this.state.amenityCategories,
      styles: this.state.styles,
      places: this.state.places,
      floors: this.state.floors,
      place: this.state.place,
      floor: this.state.floor,
      token: this.session.getToken()
    }

    return <Panel flex={1}>
      <MapContext.Provider value={context}>
        <Row flex={1}>
          <Toaster ref={this.toasterRef} position="bottom"/>
          <Map
            ref={this.mapRef}
            style={this.state.style}
            proximiioToken={this.session.getToken()}
            mapboxToken={MAPBOX_TOKEN}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            zoom={16}
            onReady={this.onMapReady}
            amenities={this.state.amenities}
            date={this.geojsonSource.date}
            floor={this.state.floor}
            floors={this.state.floors}
            place={this.state.place}
            places={this.state.places}
            selected={this.state.selected}
            selectorFeatures={this.state.selectorFeatures}
            changes={this.geojsonSource.changes}
            onSave={this.onSave}
            onDrawModeChange={this.onDrawModeChange}
            onFloorSelect={this.onFloorSelect}
            onPlaceSelect={this.onPlaceSelect}
            onMapSelection={this.onMapSelection}
            onBoxSelection={this.onBoxSelection}
            onCenterChange={this.onCenterChange}
            onFeatureCreate={this.onFeatureCreate}
            onFeatureUpdate={this.onFeatureUpdate}
            onFeatureDelete={this.onFeatureDelete}
            onFeatureQueryChange={this.onFeatureQueryChange}
            onRouteUpdate={this.onRouteUpdate}
            onFilterClick={this.onFilterClick}
            onDateSelect={this.onDateSelect}
            loadingRoute={this.state.loadingRoute}
            route={this.routingSource.data}
            onRouteCancel={this.onRouteCancel}
            onOptionsChange={this.onOptionsChange}
            onRasterToggle={this.onRasterToggle}
            onInspect={this.onInspect}
            inspect={this.state.inspect}
            usesMetadataFilter={this.state.usesMetadataFilter}
          />

          <Sidebar
            style={this.state.style}
            amenities={this.state.amenities}
            amenityCategories={this.state.amenityCategories}
            floor={this.state.floor}
            floors={this.state.floors}
            languages={LANGUAGES}
            mode={this.state.mode}
            place={this.state.place}
            places={this.state.places}
            selected={this.state.selected}
            onChange={this.onFeatureUpdate}
            onStyleSelect={this.onStyleSelect}
            onPlaceSelect={this.onPlaceSelect}
            onMigrate={this.onMigrate}
            onLogout={this.onLogout}
            onAmenityReload={this.onAmenityReload}
            options={this.state.options}
            routeOptions={this.routingSource.options}
            onRouteOptionsChange={this.onRouteOptionsChange}
            onLayerToggle={(layer: string, value: boolean) => {
              this.mapRef.current?.onLayerToggle(layer, value)
            }}
            onInspectToggle={(inspect: boolean) => {
              this.setState({ inspect })
            }}
            inspection={this.state.inspection}
            submitGeoJSON={this.submitGeoJSON}
          />
        </Row>
      </MapContext.Provider>
      <NoPlaces show={this.state.noPlaces}/>
      <FilterDialog
        isOpen={this.state.showFilter}
        geojsonSource={this.geojsonSource}
        onClose={this.onFilterClose}
      />
    </Panel>
  }
}
