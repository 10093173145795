import React from 'react'
import { Collapse, FormGroup, MenuItem, Tabs, Tab } from '@blueprintjs/core'
import { Column } from '../flex'
import Translator from './translator'
import Feature from '../../models/feature'
import Language from '../../models/language'

interface Props {
  feature: Feature
  languages: Language[],
  onChange: (feature: Feature) => void
}

interface State {
  tab: string
  isOpen: boolean
}

export default class MetadataEditor extends React.Component<Props, State> {
  state = {
    tab: 'tab-1',
    isOpen: false
  }

  renderTab(index: number) {
    return <Tab title={`${index}`} id={`tab-${index}`} panel={
      <Translator
        feature={this.props.feature}
        area={true}
        field="wayfinding_metadata"
        fieldIndex={index}
        fieldName={false}
        languages={this.props.languages}
        onChange={this.props.onChange}
      />}
    />
  }

  render() {
    const label = <MenuItem
      text="Wayfinding Metadata"
      icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
    />

    return <Column flex={1} className="edit-block menu-block">
      <FormGroup label={label}>
        <Collapse isOpen={this.state.isOpen}>
          <Tabs selectedTabId={this.state.tab} onChange={(tab: string) => this.setState({ tab })}>
            { this.renderTab(1) }
            { this.renderTab(2) }
            { this.renderTab(3) }
            { this.renderTab(4) }
            { this.renderTab(5) }
            { this.renderTab(6) }
          </Tabs>
        </Collapse>
      </FormGroup>
    </Column>
  }
}