import DataSource from './data_source'
import Feature, { FeatureCollection } from '../models/feature'
import deepEqual from 'deep-equal'
import { debug } from '../common'

export default class SyntheticSource extends DataSource {
  data: FeatureCollection

  constructor(data: FeatureCollection) {
    super('synthetic')
    this.data = data || new FeatureCollection({})
  }

  get(id: string) {
    return this.data.features.find(f => f.properties.id === id) as Feature
  }

  create(feature: Feature) {
    debug.log('[SyntheticSource] creating feature', feature)
    this.data.features.push(new Feature(feature))
    this.notify('feature-created')
  }

  update(feature: Feature) {
    debug.log('[SyntheticSource] updating feature', feature)
    const idx = this.data.features.findIndex(f => f.properties.id === feature.properties.id)
    if (idx >= 0) {
      if (!deepEqual(this.data.features[idx], feature)) {
        this.data.features.splice(idx, 1, feature)
      }
    }
    this.notify('feature-updated')
  }

  delete(feature: Feature) {
    debug.log('[SyntheticSource] deleting feature', feature)
    const idx = this.data.features.findIndex(f => f.properties.id === feature.properties.id)
    if (idx >= 0) {
      this.data.features.splice(idx, 1)
    }
    this.notify('feature-deleted')
  }
}
