import Observable from './observable'
import Amenity from './models/amenity'
import AmenityCategory from './models/amenity_category'
import { getAmenities, getAmenityCategories, createAmenity, updateAmenity } from './repository'

class AmenityStore extends Observable {
  amenities: Amenity[] = []
  categories: AmenityCategory[] = []

  async initialize() {
    await this.fetch(true);
    this.notify('initialized');
  }

  add(amenity: Amenity) {
    this.amenities = [...this.amenities, amenity];
    this.notify('add', amenity);
  }

  update = (amenity: Amenity) => {
    const idx = this.amenities.findIndex(a => amenity.id === a.id)
    if (idx >= 0) {
      this.amenities = this.amenities.filter((i, index) => index !== idx)
      this.amenities.splice(idx, 0, amenity);;
      // this.amenities[idx] = amenity
    }
    this.notify('update', amenity)
  }

  delete(amenity: Amenity) {
    this.amenities = this.amenities.filter(a => a.id !== amenity.id);
    this.notify('delete', amenity);
  }
  
  async addCategory(category: AmenityCategory) {
    this.categories.push(category)
    this.notify('update')
  }

  async deleteCategory(category: AmenityCategory) {
    const idx = this.categories.findIndex(c => category.id === c.id);
    if (idx >= 0) {
      this.categories.splice(idx, 1)
    }
    this.notify('update')
  }

  async fetch(silent = false) {
    this.amenities = await getAmenities();
    this.categories = await getAmenityCategories()
    if (silent) {
      this.notify('update')
    }
  }

  async save(_amenity: Amenity) {
    if (_amenity.exists) {
      const amenity = await updateAmenity(_amenity);
      const idx = this.amenities.findIndex(a => a.id === amenity.id);
      this.amenities.splice(idx, 1, amenity);
    } else {
      const amenity = await createAmenity(_amenity);
      this.amenities.push(amenity)
    }

    this.notify('update')
  }
}

const instance = new AmenityStore()
export default instance
