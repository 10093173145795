import React, { FunctionComponent } from 'react'
import Amenity from '../models/amenity'

interface Props {
  amenity: Amenity,
  width: number
}

export const AmenityIcon: FunctionComponent<Props> = ({amenity, width}) => <img
  alt="icon"
  style={{ width }}
  src={amenity.icon}
/>

export default AmenityIcon
