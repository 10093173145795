import Feature from './feature'
import { Diff, diff } from 'deep-diff'

export type ChangeAction = 'create' | 'update' | 'delete'

export enum DiffKinds {
  E = 'Edit',
  D = 'Delete',
  N = 'New',
  A = 'Array change'
}

export default class ChangeContainer {
  action: ChangeAction
  update: Feature
  original?: Feature
  timestamp: Date
  diff?: Diff<Feature, Feature>[]

  constructor(action: ChangeAction, update: Feature, original?: Feature) {
    this.timestamp = new Date()
    this.action = action
    this.update = update

    if (original) {
      this.original = original
      this.diff = diff(original, update)
    }
  }

  get isCreate() {
    return this.action === 'create'
  }

  get isUpdate() {
    return this.action === 'update'
  }

  get isDelete() {
    return this.action === 'delete'
  }
}