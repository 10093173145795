import Feature from '../../models/feature';
import { Collapse, FormGroup, MenuItem } from '@blueprintjs/core';
import { useState } from 'react';
import { Column, Row } from '../flex';
import { DateInput } from '@blueprintjs/datetime';
import { formatDate, parseDate } from '../../common';
import moment from 'moment';

export interface Props {
  feature: Feature
  onChange: (sinceDate: Date | null, tillDate: Date | null) => void
}

export default function EventEditor({ feature, onChange }: Props) {
  const [ isOpen, setIsOpen ]  = useState(false);
  const [ since, setSince ]  = useState<Date | null>(feature.properties.metadata && feature.properties.metadata.dateStart ? new Date(feature.properties.metadata.dateStart) : null);
  const [ till, setTill ]  = useState<Date | null>(feature.properties.metadata && feature.properties.metadata.dateEnd ? new Date(feature.properties.metadata.dateEnd) : null || null);

  const onSinceChange = (_since: Date | null) => {
    const since = moment(_since).startOf('day').toDate();
    setSince(since);
    onChange(since, till);
  }

  const onTillChange = (_till: Date | null) => {
    const till = moment(_till).endOf('day').toDate();
    setTill(till);
    onChange(since, till);
  }

  const label = <MenuItem
    text="Event Editor"
    icon={isOpen ? 'chevron-down' : 'chevron-right'}
    onClick={() => setIsOpen(!isOpen)}
  />

  return <Column flex={1} className="edit-block menu-block">
    <FormGroup label={label}>
      <Collapse isOpen={isOpen}>
        <Row flex={1}>
          <DateInput 
            formatDate={formatDate}
            parseDate={parseDate}
            value={since}
            onChange={onSinceChange}
            placeholder='Starts at'
          />
          <DateInput 
            formatDate={formatDate}
            parseDate={parseDate}
            value={till}
            onChange={onTillChange}
            placeholder='Ends at'
          />
        </Row>
      </Collapse>
    </FormGroup>
  </Column>
}
