import React from 'react'
import { FormGroup, InputGroup, TextArea, Label, Tabs, Tab } from '@blueprintjs/core'
import { Column } from '../flex'
import Feature from '../../models/feature'
import Language from '../../models/language'

interface Props {
  feature: Feature
  field: string
  fieldIndex?: number
  fieldName: string | boolean
  languages: Array<Language>
  area: boolean
  onChange: (feature: Feature) => void
}

interface State {
  tab: string
}

export default class Translator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tab: `translator-${this.props.field}-en`
    }
  }

  onChange(e: any, language: Language) {
    const value = e.target.value
    const feature = new Feature(this.props.feature.json)
    // const field = this.getFieldName(language)

    // if (this.props.field === 'title_i18n' && typeof this.props.feature.properties.title_i18n === 'undefined') {
    //   console.log('setting title_i18n', feature, value, language.code);
    //   feature.setTitle(value, language.code);
    // } else {
    //   if (!feature.properties[this.props.field]) {
    //     feature.properties[this.props.field] = {}
    //   }

    //   if (typeof this.props.fieldIndex !== 'undefined') {
    //     if (typeof feature.properties[this.props.field][this.props.fieldIndex] === 'undefined') {
    //       feature.properties[this.props.field][this.props.fieldIndex] = {}
    //     }

    //     feature.properties[this.props.field][this.props.fieldIndex][language.code] = value
    //   } else {
    //     if (typeof feature.properties[this.props.field] !== 'object') {
    //       feature.properties[this.props.field] = {}
    //     }

    //     feature.properties[this.props.field][language.code] = value
    //   }
    // }

    if (!feature.properties[this.props.field]) {
      feature.properties[this.props.field] = {}
    }

    if (typeof this.props.fieldIndex !== 'undefined') {
      if (typeof feature.properties[this.props.field][this.props.fieldIndex] === 'undefined') {
        feature.properties[this.props.field][this.props.fieldIndex] = {}
      }

      feature.properties[this.props.field][this.props.fieldIndex][language.code] = value
    } else {
      if (typeof feature.properties[this.props.field] !== 'object') {
        feature.properties[this.props.field] = {}
      }

      feature.properties[this.props.field][language.code] = value
    }

    this.props.onChange(feature)
  }

  renderArea(language: Language) {
    const fieldValue = this.props.feature.properties[this.props.field] || {}
    let value;
    if (typeof this.props.fieldIndex !== 'undefined') {
      const indexValue = fieldValue[this.props.fieldIndex] || {};
      value = indexValue[language.code] || ''
    } else {
      value = fieldValue[language.code] || ''
    }

    if (this.props.field === 'description_i18n') {
      if (typeof this.props.feature.properties['description_i18n'] === 'undefined') {
        value = this.props.feature.properties.description;
     }
    }

    return <TextArea
      value={value}
      onChange={(e:any) => {
        this.onChange(e, language)
      }}
    />
  }

  getDefaultValue() {
    const value = {} as any
    this.props.languages.forEach(language => {
      if (typeof this.props.fieldIndex !== 'undefined') {
        value[this.props.fieldIndex][language.code] = ''
      } else {
        value[language.code] = ''
      }
    })
    return value
  }

  renderTextField(language: Language) {
    let fieldValue = this.props.feature.properties[this.props.field] || this.getDefaultValue()

    let value;
    if (typeof this.props.fieldIndex !== 'undefined') {
      const indexValue = fieldValue[this.props.fieldIndex] || {}
      value = indexValue[language.code] || ''
    } else {
      value = fieldValue[language.code] || ''
    }

    // console.log('value pre', value);
    // if (this.props.field === 'title_i18n') {
    //   console.log('field is title_i18n');
    //   if (typeof this.props.feature.properties['title_i18n'] === 'undefined') {
    //     console.log('field is title_i18n and undefined');
    //     value = this.props.feature.properties.title;
    //  }
    // }

    return <InputGroup
      style={{ direction: language.rtl ? 'rtl' : 'ltr' }}
      value={value}
      onChange={(e:any) => {
        this.onChange(e, language)
      }}
    />
  }

  renderTranslation(language: Language) {
    return <Tab
      key={`translator-${this.props.field}-${language.code}`}
      id={`translator-${this.props.field}-${language.code}`}
      title={language.code}
      panel={<Column flex={1}>
        <FormGroup>
          { this.props.area ? this.renderArea(language) : this.renderTextField(language) }
        </FormGroup>
      </Column>}
    />
  }

  render() {
    return <Column flex={1} className="edit-block translator">
      { this.props.fieldName && <Label style={{marginBottom: 0}}>{ this.props.fieldName }</Label> }
      <Tabs selectedTabId={this.state.tab} onChange={(tab: string) => {
        if (this.state.tab !== tab) {
          this.setState({ tab })
        }
      }}>
      { this.props.languages.map(language => this.renderTranslation(language)) }
      </Tabs>
    </Column>
  }
}
