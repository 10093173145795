import React, { CSSProperties, PropsWithChildren } from 'react'

interface Props {
  style?: CSSProperties
  className?: string
}

export default class MapToolbar extends React.Component<PropsWithChildren<Props>> {
  render() {
    return <div className={`map-toolbar ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
      { this.props.children }
    </div>
  }
}
