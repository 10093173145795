import React, { useEffect, useState } from 'react'
import { Spinner } from '@blueprintjs/core'
import EventBus from '../event_bus';

const Loading: React.FunctionComponent = () => {
  const [ progress, setProgress ] = useState(0);
  const [ total, setTotal ] = useState(0);

  useEffect(() => {
    const observer = (event?: string, data?: any) => {
      if (event === 'loading:features') {
        const info = data as { total: number, from: number }
        setProgress(info.from);
        setTotal(info.total);
      }
    }

    EventBus.observe(observer);
    return () => {
      EventBus.cancel(observer)
    }
  }, []);

  const percentage = total > 0 ? progress / total : 0;

  return <div className="loading">
    <div className="loading-container ta-c">
      <Spinner size={50} value={percentage} intent="primary"/>
      <strong style={{ marginTop: 24 }}><br/><br/>Please wait<br/>loading data</strong>
    </div>
  </div>
}

export default Loading
