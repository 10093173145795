import React, { Component } from 'react'
import { Button, Classes, Dialog, HTMLTable } from '@blueprintjs/core'
import ChangeContainer from '../models/change_container'
import { formatDateTime } from '../common'
import { Column, Row } from './flex'
import styled from 'styled-components'

const PathContainer = styled.div`
  margin-right: 10px
`

// const DiffContainer = styled.div`
//   margin-right: 10px
// `

interface Props {
  changes: ChangeContainer[]
  onClose: () => void
}

interface State {

}

export default class ChangesInspector extends Component<Props, State> {
  state = {}

  renderBody() {
    const rows = this.props.changes.map((change, index) =>{
      let diffs = [] as JSX.Element[]

      if (change.isUpdate && change.diff) {
        diffs = change.diff.map((diff, index) => <Row key={`changes-diff-${index}`}flex={1}>
            <PathContainer>{ (diff.path || []).join('.') }</PathContainer>
            {/* <DiffContainer>{ (diff as any).lhs } => { (diff as any).rhs }</DiffContainer> */}
          </Row>
        )
      }

      return <tr key={`change-${index}`}>
        <td>{ formatDateTime(change.timestamp) }</td>
        <td>{ change.action }</td>
        <td>{ change.update.properties.id }</td>
        <td><Column flex={1}>{ diffs }</Column></td>
      </tr>
    })

    return <tbody>{ rows }</tbody>
  }

  render() {
    return <Dialog isOpen={true} title="Changes Inspector" onClose={this.props.onClose} style={{ width: 957 }}>
      <div className={Classes.DIALOG_BODY} style={{ marginTop: 0 }}>
        <HTMLTable bordered className="change-inspector-table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Action</th>
              <th>Feature</th>
              <th>Change</th>
            </tr>
          </thead>
          { this.renderBody() }
        </HTMLTable>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={this.props.onClose} text="Dismiss" icon="cross" />
        </div>
      </div>
    </Dialog>
  }
}
