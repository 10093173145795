import React, { Component } from 'react'
import { Popup } from 'react-mapbox-gl'
import { Button, Tooltip, PopoverInteractionKind } from '@blueprintjs/core'
import Feature from '../../models/feature'

interface Props {
  point: Feature
  onDelete: (feature: Feature) => void
}

export default class PointPopup extends Component<Props> {
  render() {
    return <Popup
      className="map-popup"
      coordinates={this.props.point.geometry.coordinates}
      offset={[24, -24]}
      style={{ backgroundColor: 'transparent' }}>
      <Tooltip interactionKind={PopoverInteractionKind.HOVER} intent="danger" content="Delete Feature">
        <Button intent="danger" onClick={() => {this.props.onDelete(this.props.point)}} icon="trash"/>
      </Tooltip>
    </Popup>
  }
}
