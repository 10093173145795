import React, { Component } from 'react'
import { Button, Dialog, FormGroup, InputGroup, MenuItem, Tab, Tabs } from '@blueprintjs/core'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { ObjectInput } from 'react-object-input';
import deepEqual from 'deep-equal';

interface Props {
  id: string
  value: any
  onChange: (value: any) => void
  label: string
  readonly: boolean
}

interface State {
  value: any
  isOpen: boolean
  tabId: string
  stringValue: string
}

export default class JSONEditor extends Component<Props, State> {
  private instance = React.createRef<CodeMirror>();

  constructor(props: Props) {
    super(props);
    const value = typeof props.value === 'object' ? props.value : JSON.parse(props.value);
    this.state = {
      isOpen: false,
      value,
      stringValue: this.parseValue(value),
      tabId: 'text'
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value } = this.props;
    if (!deepEqual(value, prevProps.value) || value !== prevProps.value) {
      this.setState({
        value,
        stringValue: this.parseValue(value)
      })
    }
  }

  parseValue(value: any) {
    return typeof value === 'object' ? JSON.stringify(value, null, 2) : value
  }

  private setTab = (tabId: string) => this.setState({ tabId })

  private onObjectChange = (a: any) => {
    const value = a(this.state.value);
    this.setState({ 
      value,
      stringValue: this.parseValue(value)
    })
  }

  render() {
    const label = <MenuItem
      text={this.props.label}
      icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
    />

    return <div className="edit-block menu-block">
      <FormGroup label={label}>
        <Dialog 
          isOpen={this.state.isOpen} 
          onClose={() => this.setState({ isOpen: false })}
          title="Raw GeoJSON" 
          style={{ width: '75vw', paddingBottom: 0 }}>
          { !this.props.readonly && <Tabs className="p-24" selectedTabId={this.state.tabId} onChange={this.setTab} renderActiveTabPanelOnly={true}>
            <Tab id="text" title="Text" panel={
              <ObjectInput
                obj={this.state.value}
                onChange={this.onObjectChange}
                renderAdd={(addItem) => {
                  return <Button icon="plus" text="Add Item" onClick={addItem} className="m-t-6"/>
                }}
                renderItem={(key, value, updateKey, updateValue, deleteProperty) => (
                  <div className='obj-inputs' style={{ display: 'flex', alignItems: 'center' }}>
                    <InputGroup
                      disabled={typeof value === 'object'}
                      value={key}
                      onChange={e => updateKey(e.target.value)}
                      placeholder="key"
                      onBlur={() => this.props.onChange(this.state.value)}
                    />
                    :
                    <InputGroup 
                      fill
                      disabled={typeof value === 'object'}
                      placeholder="value"
                      value={value as string || ''} // value will be undefined for new rows
                      onChange={e => updateValue(e.target.value)}
                      onBlur={() => this.props.onChange(this.state.value)}
                    />
                    <Button minimal icon="trash" onClick={() => {
                      deleteProperty();
                      setTimeout(() => {
                        this.props.onChange(this.state.value)
                      }, 500)
                    }}/>
                  </div>
                )}
              />
            } />
            <Tab id="json" title="JSON" panel={
              <CodeMirror
                ref={this.instance}
                value={this.state.stringValue}
                options={{
                  mode: 'json',
                  theme: 'github',
                  lineNumbers: true
                }}
                onBeforeChange={async (editor, data, stringValue) => {
                  this.setState({ stringValue }, () => {
                    try {
                      let object = JSON.parse(stringValue);
                      this.props.onChange(object);
                    } catch (e) {
                      console.log('unable to parse json, skipping update');
                    }
                  })
                }}
              />
            }/>
          </Tabs> }
          { this.props.readonly && <CodeMirror
              ref={this.instance}
              value={this.state.stringValue}
              options={{
                mode: 'json',
                theme: 'github',
                lineNumbers: true
              }}
              onBeforeChange={async (editor, data, stringValue) => {}}
          /> }
        </Dialog>
      </FormGroup>
    </div>
  }
}
