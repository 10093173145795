import React from 'react';
import './index.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import App from './App';
import ReactDOM from 'react-dom';

// const container = document.getElementById('root');
// const root = createRoot(container!);
// root.render(<App />);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

