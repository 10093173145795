import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, FileInput, InputGroup } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Column, Row} from '../../flex'
import { convertToBase64 } from '../../../common'
import Amenity from '../../../models/amenity'

interface Props {
  amenity: Amenity
  onDelete: (amenity: Amenity) => void
  onSave: (amenity: Amenity) => void
}

export default function AmenityPopover({
    children, amenity, onSave, onDelete
  }: React.PropsWithChildren<Props>): React.ReactElement {
  const [ title, setTitle ] = useState(amenity.exists ? amenity.title : '')
  const [ icon, setIcon ] = useState(amenity.exists ? amenity.icon : '')
  const [ showAlert, setShowAlert ] = useState(false)
  
  const ref = useRef<Popover2>(null);
  
  useEffect(() => {
    setTitle(amenity.title || '');
    setIcon(amenity.icon || '');
  }, [ amenity.title, amenity.icon ])

  const save = () => {
    const _amenity = new Amenity(amenity)
    _amenity.title = title;
    _amenity.icon = icon;
    onSave(_amenity);
    close()
  }

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value)
  }, [])

  const close = () => {
    ref.current?.setState({ isOpen: false })
  }

  return <div>
    <Popover2 
      ref={ref} 
      usePortal={false} 
      autoFocus={false} 
      shouldReturnFocusOnClose={true} 
      enforceFocus={false} 
      content={
        <div>
          <div style={{padding: 10}}>
            <Row flex={1}>
              <InputGroup
                autoFocus={true}
                fill
                style={{ width: '100%' }}
                placeholder="Enter amenity title"
                value={title}
                onChange={onTitleChange}
              />
            </Row>
            <Row flex={1}>
              <Column flex={2}>
                <FileInput
                  text={icon.length > 0 ? 'Image Selected' : 'Choose image...'}
                  inputProps={{
                    accept: ".png",
                    multiple: false
                  }}
                  hasSelection={icon.length > 0}
                  onInputChange={async value => {
                    if (value.currentTarget.files) {
                      const file = value.currentTarget.files[0]
                      const icon = await convertToBase64(file)
                      setIcon(icon)
                    }
                  }}
                />
              </Column>
            </Row>
            <Row flex={1}>
              <p className="hint">Use 128px x 128px .png image</p>
            </Row>
            <Row flex={1} style={{ justifyContent: 'end' }}>
              <Button icon="tick" intent="success" onClick={save}/>

              { amenity.exists && <Button
                icon="trash"
                intent="danger"
                minimal
                onClick={() => setShowAlert(true)} /> }

              { amenity.exists && <Alert
                isOpen={showAlert}
                cancelButtonText="Cancel"
                icon="trash"
                confirmButtonText="Remove Amenity"
                onCancel={() => setShowAlert(false)}
                onConfirm={() => onDelete(amenity)}
                intent="danger"
              > Amenity removal confirmation
              </Alert> }

              <Button icon="cross" minimal  onClick={close} />
            </Row>
          </div>
        </div>
      }>{ children }
    </Popover2>
  </div>
}
