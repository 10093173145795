import { Checkbox, ControlGroup, FormGroup } from '@blueprintjs/core';
import * as React from 'react';
import Feature from '../../models/feature';
import { RouteOptions } from '../../sources/routing_source';
import { Column } from '../flex';

export interface Props {
  start?: Feature;
  finish?: Feature;
  options: RouteOptions;
  onOptionsChange: (options: RouteOptions) => void;
}

export default class RoutingView extends React.Component<Props> {
  private onChange = (field: keyof RouteOptions, value: never) => {
    const options = Object.assign({}, this.props.options);
    options[field] = value;
    this.props.onOptionsChange(options);
  }

  public render() {
    return <React.Fragment>
      <p className="sidebar-text">Right click on map to set Start and Finish points</p>
      <Column flex={1} style={{ paddingLeft: 24, paddingRight: 24}}>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Elevators">
              <Checkbox checked={this.props.options.avoidElevators} onChange={(e) => this.onChange('avoidElevators', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Escalators">
              <Checkbox checked={this.props.options.avoidEscalators} onChange={(e) => this.onChange('avoidEscalators', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Staircases">
              <Checkbox checked={this.props.options.avoidStaircases} onChange={(e) => this.onChange('avoidStaircases', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Barriers">
              <Checkbox checked={this.props.options.avoidBarriers} onChange={(e) => this.onChange('avoidBarriers', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Hills">
              <Checkbox checked={this.props.options.avoidHills} onChange={(e) => this.onChange('avoidHills', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Narrow Paths">
              <Checkbox checked={this.props.options.avoidNarrowPaths} onChange={(e) => this.onChange('avoidNarrowPaths', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Ramps">
              <Checkbox checked={this.props.options.avoidRamps} onChange={(e) => this.onChange('avoidRamps', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Revolving Doors">
              <Checkbox checked={this.props.options.avoidRevolvingDoors} onChange={(e) => this.onChange('avoidRevolvingDoors', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
        <Column flex={1} className="edit-block-simple">
          <ControlGroup fill>
            <FormGroup inline label="Avoid Ticket Gates">
              <Checkbox checked={this.props.options.avoidTicketGates} onChange={(e) => this.onChange('avoidTicketGates', e.currentTarget.checked as never)}/>
            </FormGroup>
          </ControlGroup>
        </Column>
      </Column>
    </React.Fragment> 
  }
}
