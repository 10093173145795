import React from 'react'
import { Button, FormGroup, Icon, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import Floor from '../../models/floor'
import MapContext from '../../map_context'

const FloorSelect = Select.ofType<Floor>()

export interface Props {
  simple: boolean
  selected: Floor
  onSelect: (floor: Floor) => void
  label?: string
  noMargin?: boolean
  cancelable?: boolean
}

export const FloorSelection: React.FunctionComponent<Props> = ({ simple, selected, onSelect, label, noMargin, cancelable }) => {
  const floorRenderer = (floor: Floor, options: any) => {
    return <MenuItem
      key={`menu-item-floor-${floor.id}-${simple}-${cancelable}`}
      text={floor.name}
      label={`level #${floor.level}`}
      icon="map-marker"
      onClick={options.handleClick}
      multiline={true}
    />
  }

  const cancel = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    onSelect(new Floor({}))
  }

  return <MapContext.Consumer>{context => {
    return <FormGroup
      className={noMargin ? 'selection-no-margin' : 'selection'}
      label={simple ? false : ( label ? label : "Floor" )}
      inline={true}>
      <FloorSelect
        items={context.floors}
        itemRenderer={floorRenderer}
        onItemSelect={onSelect}>
        <Button
          text={selected.exists ? selected.name : 'Choose Floor'}
          icon="arrows-vertical"
          minimal={true}
          style={simple ? {fontWeight: 'bold'} : {}}
          rightIcon={
            <React.Fragment>
              <Icon icon="double-caret-vertical"/>
              { cancelable && <Icon icon="cross" onClick={cancel}/> }
            </React.Fragment>
          }
          className={ noMargin ? '' : "m-t-4" }
        />
      </FloorSelect>
    </FormGroup>
  }}
  </MapContext.Consumer>
}

export default FloorSelection