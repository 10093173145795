import React, { Component } from 'react'
import { Classes, Dialog, ProgressBar } from '@blueprintjs/core'

interface Props {
  isOpen: boolean
  progress: number
}

interface State {

}

export default class ImageUpload extends Component<Props, State> {
  state = {}

  render() {
    return <Dialog isOpen={this.props.isOpen} title="Image upload">
      <div className={Classes.DIALOG_BODY}>
        <h1>Please wait</h1>
        <h4>Image is being uploaded</h4>
        <ProgressBar value={this.props.progress}/>
      </div>
    </Dialog>
  }
}
