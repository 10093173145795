import React from 'react'
import { Button, MenuItem, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { Column } from '../../flex'
import Translator from '../translator'
import FloorSelection from '../../map/floor_selection'
import PlaceSelection from '../../map/place_selection'
import Feature from '../../../models/feature'
import { LANGUAGES } from '../../../models/language'
import Place from '../../../models/place'
import Floor from '../../../models/floor'
import MetadataEditor from '../metadata_editor'
import JSONEditor from '../json_editor'
import Media from '../media'
import { NodeState } from '../../../types/node_state'
import { DEFAULT_FONT, FONTS } from '../../../constants'
import { FormGroup } from '@blueprintjs/core'

const FontSelect = Select.ofType<string>()

interface Props {
  text: Feature
  places: Array<Place>
  floors: Array<Floor>
  onChange: (feature: Feature) => void
}

interface State {
  nodeState: NodeState
}

export default class EditText extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      nodeState: {}
    }
  }

  getFloor(feature: Feature) {
    const floor = this.props.floors.find(p => p.id === feature.properties.floor_id)
    if (!floor) {
      return new Floor({})
    }
    return floor
  }

  getPlace(feature: Feature) {
    const place = this.props.places.find(p => p.id === feature.properties.place_id)
    if (!place) {
      return new Place({})
    }
    return place
  }

  getIcon() {
    return null
    // const poiType = this.getType()
    // if (!poiType) {
    //   return
    // }

    // if (poiType.type === POI_TYPE.POI && this.state.amenity.exists) {
    //   return <img alt={this.state.amenity.title} className="editor-amenity" src={this.state.amenity.icon} />
    // } else {
    //   return <img alt={poiType.title} className="editor-amenity" src={poiType.icon} />
    // }
  }

  render() {
    return <Column flex={1}>
      <h6 className="bp3-heading edit-heading">
        <div style={{width: 44, float: 'left'}}>
          { this.getIcon() }
        </div>
        <Column flex={1}>
          <div style={{ fontSize: 12, fontWeight: 'bold'}}>TEXT</div>
          <div style={{ fontSize: 16 }}>{ this.props.text.properties.title }</div>
          <div style={{ fontSize: 10 }}>
            lat { this.props.text.geometry.coordinates[1].toFixed(8) }
            &nbsp;
            lng { this.props.text.geometry.coordinates[0].toFixed(8) }
            &nbsp;
            level { this.props.text.properties.level }
          </div>
          <div style={{ fontSize: 10 }}></div>
        </Column>
      </h6>

      <Column flex={1} className="edit-block">
        <PlaceSelection
          simple={false}
          selected={this.getPlace(this.props.text)}
          onSelect={(place) => {
            const point = new Feature(this.props.text.json)
            point.properties.place_id = place.id
            this.props.onChange(point)
          }}
        />
      </Column>

      <Column flex={1} className="edit-block">
        <FloorSelection
          simple={false}
          selected={this.getFloor(this.props.text)}
          onSelect={(floor) => {
            const point = new Feature(this.props.text.json)
            point.properties.floor_id = floor.id
            point.properties.level = floor.level
            this.props.onChange(point)
          }}
        />
      </Column>

      <Translator
        area={false}
        feature={this.props.text}
        field="title_i18n"
        fieldName="Title"
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      <Column flex={1} className="edit-block">
        <FormGroup inline={true} label="Font">
          <FontSelect
            items={FONTS}
            activeItem={this.props.text.properties.textFont ? this.props.text.properties.textFont : DEFAULT_FONT }
            onItemSelect={(font) => {
              const feature = new Feature(this.props.text.json)
              feature.properties.textFont = font
              this.props.onChange(feature)
            }}
            itemRenderer={(font, options) => <MenuItem
              key={`font-item-${font}`}
              text={font}
              onClick={options.handleClick}
            />
          }><Button text={this.props.text.properties.textFont ? this.props.text.properties.textFont : DEFAULT_FONT } />
          </FontSelect>
        </FormGroup>
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup label="Text Size" inline={true}>
          <NumericInput
            buttonPosition="none"
            value={this.props.text.properties.textSize}
            onValueChange={(number) => {
              const feature = new Feature(this.props.text.json)
              feature.properties.textSize = number
              this.props.onChange(feature)
            }}/>
        </FormGroup>
      </Column>
      
      <JSONEditor
        readonly={false}
        label="General Metadata"
        id={this.props.text.properties.id}
        value={this.props.text.properties.metadata || "{}"}
        onChange={(value) => {
          const feature = new Feature(this.props.text.json)
          feature.properties.metadata = value
          this.props.onChange(feature)
        }}
      />

      <MetadataEditor
        feature={this.props.text}
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      <Media feature={this.props.text} onChange={this.props.onChange}/>

      <JSONEditor
        readonly={true}
        label="Raw GeoJSON"
        id={`${this.props.text.properties.id}-raw`}
        value={JSON.stringify(this.props.text, null, 2)}
        onChange={(value) => {
          try {
            const data = JSON.parse(value)
            const feature = new Feature(data)
            this.props.onChange(feature)
          } catch (e) {
            console.error('Raw JSON Editor Format ERROR', e)
          }
        }}
      />
    </Column>
  }
}