import React, { Component } from 'react'
import { Collapse, FileInput, FormGroup, MenuItem } from '@blueprintjs/core'
import ReactImg from 'react-image'
import { Column, Row } from '../flex'
import ImageUpload from './image_upload'
import ImageViewer from './image_viewer'
import Feature from '../../models/feature'
import { axios, BaseURL } from '../../common'
import MapContext, { MapContextInterface } from '../../map_context'

interface Props {
  feature: Feature
  onChange: (feature: Feature) => void
}

interface State {
  isOpen: boolean
  uploading: boolean
  viewing: boolean
  selected: string
  selectedIndex: number
}

export default class Media extends Component<Props, State> {
  static contextType = MapContext

  state = {
    isOpen: false,
    uploading: false,
    viewing: false,
    selected: '',
    selectedIndex: 0
  }

  constructor(props: Props) {
    super(props)
    this.onInputChange = this.onInputChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  async onInputChange(e: any) {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const data = new FormData()
      data.append('file', e.currentTarget.files[0])
      this.setState({ uploading: true }, async () => {
        const response = await axios.post('/v5/geo/images', data)
        const filename = response.data.filename
        const clone = new Feature(this.props.feature.json)
        if (!Array.isArray(clone.properties.images)) {
          clone.properties.images = []
        }
        
        clone.properties.images.push(filename)
        this.props.onChange(clone)
        this.setState({ uploading: false })
      })
    }
  }

  async onDelete(index: number) {
    const clone = new Feature(this.props.feature.json)
    const image = clone.properties.images[index]
    clone.properties.images.splice(index, 1)
    this.props.onChange(clone)
    await axios.delete(`/v5/geo/images/${image}`)
    this.setState({ viewing: false, selected: '', selectedIndex: 0 })
  }

  renderItem(index: number) {
    const url = `${BaseURL}/v5/geo/${this.props.feature.properties.images[index]}?token=${(this.context as MapContextInterface).token}`
    return <Column
      key={`image-${index}-${this.props.feature.properties.id}`}
      flex={0}
      className="feature-media-container">
      <div
        className="feature-media"
        onClick={() => {
          this.setState({
            viewing: true,
            selected: url,
            selectedIndex: index
          })
        }
      }>
        <ReactImg
          key={`${this.props.feature.properties.id}-image-${index}`}
          src={url}
          alt={`image #${index}`}
          // spinner={<Spinner size={Spinner.SIZE_STANDARD} intent="primary"/>}
        />
      </div>
    </Column>
  }

  renderItems() {
    const images = this.props.feature.properties.images || []
    const perRow = 3
    const rowCount = Math.floor(images.length / perRow) + (images.length % perRow > 0 ? 1 : 0)
    const rows = []
    for (let row = 0; row < rowCount; row++) {
      const rowMod = images.length % perRow
      const itemsInRow = row === rowCount - 1 ? (rowMod === 0 ? perRow : rowMod) : perRow
      const items = []
      for (let item = 0; item < itemsInRow; item++) {
        const idx = row * 3 + item
        items.push(this.renderItem(idx))
      }
      rows.push(<Row key={`media-row-${this.props.feature.properties.id}-${row}`} flex={1}>{items}</Row>)
    }
    return rows
  }

  render() {
    const label = <MenuItem
      text="Media"
      icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
    />
    return <div className="edit-block menu-block">
      <FormGroup label={label}>
        <Collapse isOpen={this.state.isOpen}>
          { this.renderItems() }
          <FileInput
            style={{ width: '100%', marginTop: 12 }}
            text="Add file..."
            inputProps={{
              accept: ".jpg,.png,.jpeg",
              multiple: false
            }}
            onInputChange={this.onInputChange}
          />
          <ImageUpload
            isOpen={this.state.uploading}
            progress={0.5}
          />
          <ImageViewer
            isOpen={this.state.viewing}
            url={this.state.selected}
            index={this.state.selectedIndex}
            onDelete={this.onDelete}
            onClose={() => {
              this.setState({ viewing: false, selected: '', selectedIndex: 0})
            }}
          />
        </Collapse>
      </FormGroup>
    </div>
  }
}
