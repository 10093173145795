import React from 'react';
import Main from './Main'

import './App.scss'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import 'mapbox-gl/dist/mapbox-gl.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'mapbox-gl-controls/theme.css'

const App: React.FC = () => {
  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App;
