import React from 'react'
import Amenity from './models/amenity'
import AmenityCategory from './models/amenity_category'
import Style from './models/style'
import Place from './models/place'
import Floor from './models/floor'

export interface MapContextInterface {
  amenities: Amenity[]
  amenityCategories: AmenityCategory[]
  styles: Style[]
  places: Place[]
  floors: Floor[]
  place: Place
  floor: Floor
  token: string
}

export default React.createContext<MapContextInterface>({
  amenities: [],
  amenityCategories: [],
  styles: [],
  places: [],
  floors: [],
  place: new Place({}),
  floor: new Floor({}),
  token: ''
})
