import React, { ReactElement } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'
import { Column } from './flex'

interface Props {
  show: boolean
}

export default function NoPlaces({ show }: Props): ReactElement {
  return <Dialog isOpen={show}>
    <div className={Classes.DIALOG_BODY}>
      <Column flex={1}>
        <h3>Start by adding a Place</h3>
        <Button
          text="Add Place"
          icon="plus"
          intent="primary"
          onClick={() => {
            window.location.href = "https://portal.proximi.io/#/locations/places?resultsPerPage=10&currentPage=0&sortBy=createdAt&sortOrder=desc&selectedQueryField=name&searchQuery="
          }}
        />
      </Column>
    </div>
  </Dialog>
}
