import React, { Component } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'
import Img from 'react-image'

interface Props {
  url: string
  index: number
  isOpen: boolean
  onClose: () => void
  onDelete: (index: number) => void
}

interface State {

}

export default class ImageViewer extends Component<Props, State> {
  state = {}

  render() {
    return <Dialog isOpen={this.props.isOpen} style={{ width: 940 }} title="Image Viewer">
      <div className={Classes.DIALOG_BODY}>
        <div className="image-viewer-container">
        <Img
          src={this.props.url}
          alt={`image #${this.props.index}`}
        />
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Delete" icon="cross" intent="danger" onClick={() => this.props.onDelete(this.props.index)}/>
          <Button text="Close" icon="cross" onClick={this.props.onClose}/>
        </div>
      </div>
    </Dialog>
  }
}
