import React from 'react'
import { IconName, Tooltip, Button, PopoverPosition } from '@blueprintjs/core'
import { DrawMode } from '../../types/draw_mode'

import measureIcon from '../../assets/icon-ruler.png'

interface Props {
  label: string
  mode: DrawMode
  onSelect: (mode: DrawMode) => void
  icon: IconName
  current: DrawMode
}

export default function DrawModeItem({ label, mode, onSelect, icon, current } : Props) {
  const _icon = mode === 'measure' ? <img alt="" src={measureIcon}/> : icon
  const style = mode === 'measure' ? { padding: 0 } : {}

  return <Tooltip position={PopoverPosition.LEFT} content={label}>
    <Button
      onClick={() => { onSelect(mode) }}
      intent={mode === current ? 'primary' : 'none'}
      active={mode === current}
      icon={_icon}
      style={style}
      minimal
    />
  </Tooltip>
}
