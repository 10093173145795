import React from 'react'
import { Spinner, Button, PopoverPosition, Tooltip, Drawer, Position, Classes } from '@blueprintjs/core'
import Toolbar from './toolbar'
import { Row } from '../flex'
import { secondsToHuman } from '../../common'
const WALKING_SPEED = 0.833;

interface Props {
  route: any;
  isLoading: boolean;
  onCancel: () => void;
}

interface State {
  showDirections: boolean;
}

export default class RouteBar extends React.Component<Props, State> {
  state = { 
    showDirections: false 
  }

  showDirections = () => this.setState({ showDirections: true });
  closeDirections = () => this.setState({ showDirections: false });

  private renderDirections = () => {
    return this.props.route?.properties?.steps.map((step: any, index: number) => {
      const distance = `${ step.distanceFromLastStep.toFixed(0) }m`;
      const duration = `${(step.distanceFromLastStep / WALKING_SPEED).toFixed(0)}s`;

      return <div className='direction-item'>
        <Row flex={3}>
          <strong className='title'>{index}. { step.direction }</strong>
          <div className='distance'>
            { distance } ({duration})</div>
        </Row>
        <Row flex={1} className="instruction">
          { (step.instruction || []).map((i: any) => <div>{i}</div>) }
        </Row>
      </div>
    })
  }

  renderLoading() {
    if (!this.props.isLoading) {
      return
    }

    return <Spinner intent="warning" size={24}/>
  }

  renderContent() {
    if (this.props.isLoading) {
      return
    }

    return <div className="route-bar-content">
      <strong>{ this.props.route?.properties?.distanceMeters?.toFixed(1) }</strong> meters
      &nbsp;|&nbsp;&nbsp;
      <strong>{ secondsToHuman(this.props.route?.properties?.distanceMeters / WALKING_SPEED) }</strong>
    </div>
  }

  render() {
    return <Toolbar className="route-bar">
      <div id="route-bar">
        <Row flex={1}>
          <Tooltip position={PopoverPosition.TOP_RIGHT} content="Cancel navigation">
            <Button onClick={this.props.onCancel} intent="danger" icon={!this.props.isLoading && "cross"}/>
          </Tooltip>

          { this.renderLoading() }
          { this.renderContent() }
          { this.props.route && <Tooltip position={PopoverPosition.TOP_RIGHT} content="Show directions">
            <Button onClick={this.showDirections} intent="primary" icon={!this.props.isLoading && "info-sign"}/>
          </Tooltip> }
        </Row>
      </div>

      <Drawer
        style={{ width: 300 }}
        isOpen={this.state.showDirections} 
        position={Position.RIGHT} 
        onClose={this.closeDirections}
        canOutsideClickClose={true}>
        <div className={Classes.DRAWER_HEADER}>
          <h2>Routing Path Directions</h2>
        </div>
        <div className={Classes.DRAWER_BODY}>
          { this.renderDirections() }
        </div>
        <div className={Classes.DRAWER_FOOTER}>

        </div>
      </Drawer>
    </Toolbar>
  }
}