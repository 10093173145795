import React from 'react'
import { Button, FormGroup, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import Place from '../../models/place'
import MapContext from '../../map_context'

const PlaceSelect = Select.ofType<Place>()

export interface Props {
  simple: boolean
  selected: Place
  onSelect: (place: Place) => void
  label?: string
  noMargin?: boolean
  cancelable?: boolean
}

export const PlaceSelection: React.FunctionComponent<Props> = ({ simple, selected, onSelect, label, noMargin, cancelable }) => {
  const placeRenderer = (place: Place, options: any) => {
    return <MenuItem
      key={place.id}
      text={place.name.split(';')[0]}
      icon="map-marker"
      onClick={options.handleClick}
      multiline={true}
    />
  }

  return <MapContext.Consumer>{context =>
    <FormGroup
      className={noMargin ? 'selection-no-margin' : 'selection'}
      label={simple ? false : ( label ? label : "Place" )}
      inline={true}>
      <PlaceSelect
        items={context.places}
        itemRenderer={placeRenderer}
        activeItem={selected}
        onItemSelect={onSelect}>
        <Button
          text={selected.exists ? selected.name.split(';')[0] : 'Choose Place'}
          icon="home"
          style={simple ? { fontWeight: 'bold', maxWidth: 170 } : { maxWidth: 170 }}
          minimal={true}
          rightIcon="double-caret-vertical"
          className={ noMargin ? '' : "m-t-4" }
        />
      </PlaceSelect>
    </FormGroup>
  }</MapContext.Consumer>
}

export default PlaceSelection