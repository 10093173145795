import Observable from '../observable'

export type BaseSourceType = "vector" | "raster" | "raster-dem" | "geojson" | "image" | "video"

export default class BaseSource extends Observable {
  id: string
  type: BaseSourceType
  mapbox_logo = true
  
  constructor(id: string, type: BaseSourceType) {
    super()
    this.id = id
    this.type = type
  }

  get source() {
    return {
      type: this.type
    }
  }
}
