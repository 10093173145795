import React from 'react'
import { Popover, PopoverPosition, Text } from '@blueprintjs/core'
import { Column, Row } from '../../flex'

export type HelpOption = {
  title: string
  value: string
}

interface Props {
  label: string,
  description?: string
  requirement?: string
  defaults? : string
  options? : HelpOption[]
}

const HelpLabel: React.FC<Props> = ({ label, description, requirement, defaults, options }) => {
  if (!description) {
    return <Text>{ label }</Text>
  }

  return <Popover position={PopoverPosition.BOTTOM_LEFT}>
    <div className="form-help-label">{ label }</div>
    <div className="form-help-container">
      <div className="form-help-content">
        { description }
      </div>
      { requirement && <div className="form-help-requirement">
        { requirement }
      </div>}
      { defaults && <div className="form-help-defaults">
        Defaults to: <strong>{ defaults }</strong>
      </div>}
      { options && <div className="form-help-options">
        <div>Available options:</div>
        { options.map((option, index) => <Row flex={1} className="form-help-option-row" key={`${label}-${index}`}>
          <Column flex={1} className="form-help-option-title">{option.title}</Column>
          <Column flex={3}>{option.value}</Column>
        </Row>) }
      </div>}
    </div>
  </Popover>
}

export default HelpLabel