import Amenity from './models/amenity'
import AmenityCategory from './models/amenity_category'
import Place from './models/place'
import Floor from './models/floor'
import Style from './models/style'
import Feature from './models/feature'

import {axios, debug} from './common'

const LIMIT = 5000

async function fetchAmenities(from: number, size: number) {
  const url = `/v7/geo/amenities?from=${from}&size=${size}&isolated=true`;
  try {
    const { data, headers } = await axios.get(url);
    return { amenities: data, total: parseInt(headers['record-total'], 10) };
  } catch (error) {
    console.error('Error fetching features:', error);
    throw error;
  }
}

export const getAmenities = async () => {
  const items = [] as Feature[];
  let from = 0;
  let size = 100;
  let totalRecords = 0;

  // Fetch the total number of records for the first time
  const firstResponse = await fetchAmenities(from, size);
  totalRecords = firstResponse.total;
  // recordsFetched += firstResponse.amenities.length;
  items.push(...firstResponse.amenities);

  // Calculate the number of parallel requests to make
  const numParallelRequests = Math.ceil(totalRecords / size);

  // Define the number of queues
  const numQueues = 8;

  // Calculate the number of requests per queue
  const requestsPerQueue = Math.ceil(numParallelRequests / numQueues);

  // Create an array to hold all queues
  const queues = [];

  // Create and populate the queues with requests
  for (let i = 0; i < numQueues; i++) {
    const queueRequests = [];
    for (let j = 0; j < requestsPerQueue; j++) {
      const pageIndex = i * requestsPerQueue + j;
      if (pageIndex < numParallelRequests) {
        const newFrom = from + pageIndex * size;
        if (pageIndex !== 0) { // Skip the first request
          queueRequests.push(fetchAmenities(newFrom, size));
        }
      }
    }
    queues.push(queueRequests);
  }

  // Execute all queues with limited concurrency
  for (const queue of queues) {
    const results = await Promise.all(queue);
    results.forEach(result => {
      items.push(...result.amenities);
    });
  }

  // const amenitiesRes = await axios.get(`/v5/geo/amenities?size=${LIMIT}&isolated=false`)
  return items.map((item: any) => new Amenity(item))
}

export const getAmenityCategories = async () => {
  const categoriesRes = await axios.get(`/v5/geo/amenity_categories?size=${LIMIT}`)
  return categoriesRes.data.map((item: any) => new AmenityCategory(item))
}

export const getStyle = async (id?: string) => {
  let url = '/v5/geo/style'
  if (id) {
    url += `s/${id}`
  }
  const res = await axios.get(url)
  return new Style(res.data)
}

export const getStyles = async () => {
  let url = '/v5/geo/styles'
  const res = await axios.get(url)
  return res.data.map((item: any) => new Style(item))
}

export const getPlaces = async (skip: number = 0) => {
  const res = await axios.get(`/core/places?skip=${skip}&limit=${LIMIT}`)
  return res.data.map((item: any) => new Place(item))
}

export const getFloors = async (skip: number = 0, place_id?: string) => {
  const params = [ `skip=${skip}&limit=${LIMIT}` ]
  if (place_id) {
    params.push(`'place_id=${place_id}`)
  }
  const res = await axios.get(`/core/floors?${params.join('&')}`)
  const filter =  place_id ? (item: any) => item.place_id === place_id : (i: any) => i
  return res.data.filter(filter).map((item: any) => new Floor(item)).reverse() as Floor[]
}

export const getPackage = async () => {
  const result: any = {}
  const promises = [
    getPlaces().then(places => result.places = places),
    getFloors().then(floors => result.floors = floors),
    getStyle().then(style => result.style = style),
    getStyles().then(styles => result.styles = styles)
  ]
  await Promise.all(promises)
  return result
}

export const createAmenity = async (amenity: Amenity) => {
  const res = await axios.post('/v5/geo/amenities?isolated=true', amenity)
  return new Amenity(res.data)
}

export const createAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.post('/v5/geo/amenity_categories', category)
  return new AmenityCategory(res.data)
}

export const updateAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.put(`/v5/geo/amenity_categories/${category.id}`, category)
  return new AmenityCategory(res.data)
}

export const deleteAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.delete(`/v5/geo/amenity_categories/${category.id}`)
  return new AmenityCategory(res.data)
}

export const updateAmenity = async (amenity: Amenity) => {
  const res = await axios.post(`/v5/geo/amenities`, amenity)
  return new Amenity(res.data)
}

export const deleteAmenity = async (amenity: Amenity) => {
  await axios.delete(`/v5/geo/amenities/${amenity.id}`)
  return true
}

export const saveFeatures = async (features: Feature[]) => {
  features.forEach(feature => {
    const keysToRemove = Object.keys(feature.properties).filter(key => key.match('__level'));
    keysToRemove.forEach(key => {
      delete feature.properties[key]
    });
  });
  const res = await axios.post('/v7/geo/features', {
    type: 'FeatureCollection',
    features: features.map(feature => feature.json)
  })
  return new Amenity(res.data)
}

export const publishFeatures = async () => {
  try {
    await axios.post('/bundler/publish', {
      "format": "json",
      "upload": true,
      "id_compression": false,
      "places": false,
      "floors": false,
      "kiosks": false,
      "amenities": false,
      "features": true,
      "style": false
    });
    return true
  } catch (error) {
    debug(error);
    return false
  }
}

export const deleteFeatures = async (_features: Feature[]) => {
  const features = _features.map(f => f.json)
  const res = await axios.post('/v7/geo/features/delete', {
    type: 'FeatureCollection',
    features
  })
  return res.data
}

const index = {
  getPackage,
  getStyle,
  getStyles,
  getPlaces,
  getFloors,
  getAmenities,
  getAmenityCategories,
  saveFeatures,
  publishFeatures,
  deleteFeatures,
  createAmenity,
  updateAmenity,
  deleteAmenity,
  createAmenityCategory,
  updateAmenityCategory,
  deleteAmenityCategory
}

export default index;
