import React, { Component } from 'react'
import { Button, Tooltip, PopoverPosition } from '@blueprintjs/core'
import Toolbar from './toolbar'
import { Row } from '../flex'
import ChangeContainer from '../../models/change_container'
import ChangesInspector from '../changes_inspector'

interface Props {
  changes: ChangeContainer[]
  onSave: () => void
}

interface State {
  inspect: boolean
}

export default class ChangesBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.toggleInspect = this.toggleInspect.bind(this)
  }

  state = {
    inspect: false
  }

  inspect() {
    if (!this.state.inspect) {
      return
    }

    return <ChangesInspector changes={this.props.changes} onClose={() => {
      this.setState({ inspect: false })
    }}/>
  }

  toggleInspect() {
    this.setState({ inspect: !this.state.inspect })
  }

  renderContent() {
    const human = this.props.changes.length === 1 ? 'change' : 'changes'
    return <Tooltip content="Show Changes Inspector">
      <div onClick={this.toggleInspect} className="changes-bar-content">
        <strong>{ this.props.changes.length }</strong> { human }
      </div>
    </Tooltip>
  }

  render() {
    return <Toolbar className="changes-bar">
      <div id="changes-bar">
        <Row flex={1}>
          <Tooltip position={PopoverPosition.TOP_RIGHT} content="SAVE CHANGES">
            <Button onClick={this.props.onSave} intent="danger" icon="upload" />
          </Tooltip>

          { this.renderContent() }
        </Row>
      </div>

      { this.inspect() }
    </Toolbar>
  }
}
