import axios from 'axios'
const KEY_TOKEN = 'token'

export default class Session {
  loggedIn: boolean = false

  constructor() {
    this.restore()
  }

  restore() {
    const token = localStorage.getItem(KEY_TOKEN)
    if (token) {
      this.setAxios(token)
      this.loggedIn = true
    }
  }

  getToken() {
    return localStorage.getItem(KEY_TOKEN) || ''
  }

  save(token: string) {
    localStorage.setItem(KEY_TOKEN, token)
    this.setAxios(token)
    this.loggedIn = true
  }

  clear() {
    localStorage.removeItem(KEY_TOKEN)
    this.setAxios()
    this.loggedIn = false
  }

  setAxios(token?: string) {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  }
}
