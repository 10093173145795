import React from 'react'
import { DrawMode } from '../../types/draw_mode'
import DrawModeItem from './draw_mode_item'
import Toolbar from './toolbar'
import { IconName } from '@blueprintjs/core'

type DrawItem = {
  label: string
  mode: DrawMode
  icon: IconName
}

const DrawItems = [
  { label: 'Select Tool', mode: 'simple_select', icon: 'hand-up' },
  { label: 'Add Point', mode: 'draw_point', icon: 'map-marker' },
  { label: 'Add Path', mode: 'draw_line_string', icon: 'layout-linear' },
  { label: 'Add Polygon', mode: 'draw_polygon', icon: 'polygon-filter' },
  { label: 'Add Text', mode: 'draw_text', icon: 'font' },
  { label: 'Measure', mode: 'measure', icon: 'flow-review' },
  { label: 'Visibility', mode: 'visibility', icon: 'eye-open' },
  { label: 'Navigation', mode: 'routing', icon: 'walk' },
  { label: 'Settings', mode: 'settings', icon: 'cog' }
] as DrawItem[]

interface Props {
  mode: DrawMode
  onSelect: (mode: DrawMode) => void
}

export default function DrawTools({ mode, onSelect }: Props): React.ReactElement {
  return <Toolbar className="draw-tools">
    { DrawItems.map(item =>
      <DrawModeItem
        key={item.mode}
        label={item.label}
        mode={item.mode}
        icon={item.icon}
        onSelect={onSelect}
        current={mode}
      />
    )}
  </Toolbar>
}
