export type Priority = {
  id: number
  title: string
}

export const Priorities: Priority[] = [
  {id: 0, title: "Lowest"},
  {id: 1, title: "Low"},
  {id: 2, title: "Medium"},
  {id: 3, title: "High"},
  {id: 4, title: "Highest"}
]
