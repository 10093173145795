import React from 'react'
import { Button, ControlGroup, Divider, FormGroup, MenuItem, Tooltip, PopoverPosition } from '@blueprintjs/core'
import { Select, Suggest } from '@blueprintjs/select'
import { Row } from '../flex'
import MapContext, { MapContextInterface } from '../../map_context'

import Floor from '../../models/floor'
import Place from '../../models/place'
import Feature from '../../models/feature'
import { ellipsis, formatDate, parseDate } from '../../common'
import { DateInput } from '@blueprintjs/datetime'

const PlaceSelect = Select.ofType<Place>()
const FloorSelect = Select.ofType<Floor>()
const Search = Suggest.ofType<Feature>()

interface Props {
  date: Date | null
  features: Feature[]
  floor: Floor
  floors: Array<Floor>
  place: Place
  places: Array<Place>
  onFeatureQueryChange: (query: string) => void
  onFeatureSelect: (feature: Feature) => void
  onFloorSelect: (floor: Floor) => void
  onPlaceSelect: (place: Place) => void
  onDateSelect: (date: Date | null) => void
  onFilterClick: () => void
  usesMetadataFilter: boolean
}

interface State {
  query: string
  selectedFeature?: Feature
}

export default class Selector extends React.Component<Props, State> {
  static contextType = MapContext
  state = {
    query: ''
  } as State

  floorRef = React.createRef<Select<Floor>>()

  placeRenderer (place: Place, options: any) {
    return <MenuItem
      id={`selector-place-${place.id}`}
      key={`selector-place-${place.id}`}
      text={place.name.split(';')[0]}
      icon="map-marker"
      onClick={options.handleClick}
      multiline={true}
    />
  }

  floorRenderer (floor: Floor, options: any) {
    return <MenuItem
      id={`selector-floor-${floor.id}`}
      key={`selector-floor-${floor.id}`}
      text={floor.name.split(';')[0]}
      label={`level #${floor.level}`}
      icon="map-marker"
      onClick={options.handleClick}
      multiline={true}
    />
  }

  getIcon(feature: Feature) {
    if (!feature.properties.amenity) {
      return null
    }

    const ctx = (this.context as MapContextInterface)
    const amenity = ctx.amenities.find(a => a.id === feature.properties.amenity)
    if (!amenity) {
      return null
    }

    return <img src={amenity.icon} alt={amenity.title} className="list-icon" />
  }

  render() {
    return <Row flex={1} className="map-selector">
      <div className="map-selector-item"  style={{ flex: 1 }}>
        <FormGroup
          className="selection-no-margin"
          inline={true}>
          <PlaceSelect
            items={this.props.places}
            itemRenderer={this.placeRenderer}
            activeItem={this.props.place}
            onItemSelect={this.props.onPlaceSelect}>
            <Button
              icon="home"
              style={{ fontWeight: 'bold' }}
              minimal={true}
              rightIcon="double-caret-vertical"
              text={this.props.place.exists ? ellipsis(this.props.place.name.split(';')[0]) : 'Choose Place'}
            />
          </PlaceSelect>
        </FormGroup>
      </div>
      <Divider />
      <div className="map-selector-item" style={{ flex: 1 }}>
        <FormGroup
          className={'selection-no-margin'}
          inline={true}>
          <FloorSelect
            ref={this.floorRef}
            items={this.props.floors}
            itemRenderer={this.floorRenderer}
            onItemSelect={this.props.onFloorSelect}>
            <Button
              text={this.props.floor.exists ? ellipsis(this.props.floor.name.split(';')[0]) : 'Choose Floor'}
              icon="arrows-vertical"
              minimal={true}
              style={{fontWeight: 'bold'}}
              rightIcon="double-caret-vertical"
            />
          </FloorSelect>
        </FormGroup>
      </div>
      <Divider />
      <div className="map-selector-item" style={{ flex: 1 }}>
        <FormGroup
          className={'selection-no-margin'}
          inline={true}>
          <DateInput 
            value={this.props.date}
            onChange={this.props.onDateSelect}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder='Date Filter'
            inputProps={{
              className: 'date-input'
            }}
          />
        </FormGroup>
      </div>
      <Divider />
      <div className="map-selector-item"  style={{ flex: 1 }}>
        <ControlGroup style={{ width: 220 }}>
          <FormGroup
            className="selection-no-margin"
            inline={true}>
            <Search
              initialContent={
                <div className="list-no-results">Enter 2 or more characters to search</div>
              }
              noResults={
                <div className="list-no-results">Sorry, no results</div>
              }
              inputValueRenderer={feature =>
                this.props.features.find(f => f.id === feature.id) ? feature.properties.title : ''
              }
              itemRenderer={(feature, options) => <MenuItem
                key={`search-item-${feature.properties.id}`}
                text={feature.properties.title}
                icon={this.getIcon(feature)}
                label={feature.properties.type}
                multiline={true}
                onClick={options.handleClick}
              />}
              items={this.props.features}
              onItemSelect={feature => {
                this.setState({ query: feature.properties.title, selectedFeature: feature })
                this.props.onFeatureSelect(feature)
              }}
              onQueryChange={query => {
                this.setState({ query })
                if (query.length > 2) {
                  this.props.onFeatureQueryChange(query)
                }
              }}
              query={this.state.query}
              resetOnClose={true}
            />
          </FormGroup>
          <Button minimal icon="cross" disabled={this.state.query.length === 0} onClick={async () => {
            this.setState({ query: '', selectedFeature: undefined }, () => {
              this.props.onFeatureQueryChange('')
            })
          }}/>
        </ControlGroup>
      </div>
      <div className="map-selector-item" style={{ marginLeft: 24, width: 30 }}>
        <Tooltip position={PopoverPosition.BOTTOM} content="">
          <Button onClick={() => { this.props.onFilterClick() }} icon="filter" intent={this.props.usesMetadataFilter ? 'danger' : 'none'} minimal />
        </Tooltip>
      </div>
      <div className="map-selector-item" style={{ width: 30 }}>
        <Tooltip position={PopoverPosition.BOTTOM} content={
          this.state.selectedFeature ? "Center to selection" : "Center to Place"
        }>
          <Button onClick={() => { this.props.onPlaceSelect(this.props.place) }} icon="locate" minimal />
        </Tooltip>
      </div>
    </Row>
  }
}