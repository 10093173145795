import React, { Component } from 'react'
import { Alignment, Dialog, Classes, Colors, Icon, Button, MenuItem, InputGroup, Navbar, NavbarDivider, NavbarGroup, NavbarHeading } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import uuid from 'uuid'
import produce from 'immer'
import { orderBy } from 'lodash'
import { Column, Row } from '../flex'
import AmenityPopover from './amenities/amenity_popover'
import Amenity from '../../models/amenity'
import AmenityCategory from '../../models/amenity_category'
import { NodeState } from '../../types/node_state'
import amenityStore from '../../amenity_store'
import repository from '../../repository'
import notifier from '../../notifier'
import { Popover2 } from '@blueprintjs/popover2'

type AmenityMap = { [id: string]: Amenity[] }
type CategoryMap = { [id: string]: AmenityCategory }

const CategorySelect = Select.ofType<AmenityCategory>()

interface Props {
  isOpen: boolean
  onClose: () => void
}

interface State {
  tab: string,
  mapping?: string
  amenities: Amenity[]
  amenityCategories: AmenityCategory[]
  category: AmenityCategory
  categories: CategoryMap
  nodes: AmenityMap
  nodeState: NodeState
  selected?: Amenity
  selectedCategory?: AmenityCategory
  newAmenityOpen: boolean
  newAmenityCategoryOpen: boolean
  amenityTitle: string
  categoryTitle: string
  amenityIcon: string
  deleteCategoryOpen: boolean
}

export default class Amenities extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tab: 'categories',
      mapping: 'poi',
      amenities: [],
      amenityCategories: [],
      category: new AmenityCategory({}),
      categories: {},
      nodes: {},
      nodeState: {},
      newAmenityOpen: false,
      newAmenityCategoryOpen: false,
      amenityTitle: '',
      categoryTitle: '',
      amenityIcon: '',
      deleteCategoryOpen: false
    }
  }

  componentDidMount() {
    amenityStore.observe(this.storeObserver)
  }

  componentWillUnmount() {
    amenityStore.cancel(this.storeObserver)
  }

  storeObserver = () => {
    this.update()
  }

  update = () => {
    this.setState(produce(this.state, state => {
      state.amenities = [...amenityStore.amenities];
      state.amenityCategories = [...amenityStore.categories];
      state.nodeState = {}
      state.amenities.forEach(amenity => state.nodeState[amenity.id] = false)
      if (!state.category.exists && state.amenityCategories.length > 0) {
        state.category = state.amenityCategories[0]
      }
    }), () => {
      this.setState(produce(this.state, state => {
        state.categories = this.mapCategories()
        state.nodes = this.mapNodes()
      }))
    })
  }

  mapCategories = () => {
    const categoryMap = {} as CategoryMap
    orderBy(this.state.amenityCategories, ['title', 'asc'])
      .forEach(amenityCategory => categoryMap[amenityCategory.id] = amenityCategory)

    return categoryMap
  }

  mapNodes = () => {
    const amenityMap = {} as AmenityMap

    this.state.amenities.forEach(amenity => {
      const category = amenityMap[amenity.category || 'general'] || []
      category.push(amenity)
      amenityMap[amenity.category] = category
    })

    return amenityMap
  }

  onOpened = async () => {
    await this.update()
  }

  save = async () => {
    if (this.state.selected) {
      await amenityStore.save(this.state.selected)
    }
  }

  addCategory = () => {
    this.setState(produce(this.state, state => {
      state.amenityCategories.push(new AmenityCategory({ title: 'New Category'}))
    }))
  }

  createAmenity = async (amenity: Amenity) => {
    amenity.category = this.state.category.id
    try {
      const response = await repository.createAmenity(amenity)
      amenityStore.add(new Amenity(response))
      this.setState({ newAmenityOpen: false, amenityTitle: '', amenityIcon: '' }, async () => {
        await this.update()
        notifier.success('Amenity was successfuly created');
      })
    } catch (e) {
      notifier.failure('Unable to create amenity')
    }
  }

  updateAmenity = async (amenity: Amenity) => {
    amenity.category = this.state.category.id
    try {
      const response = await repository.updateAmenity(amenity);
      amenityStore.update(response);
      this.setState({ newAmenityOpen: false, amenityTitle: '', amenityIcon: '' }, () => {
        this.update();
        notifier.success('Amenity was successfuly updated');
      })
    } catch (e) {
      console.error('amenity update failure', e);
      notifier.failure('Unable to update amenity')
    }
  }

  createCategory = async () => {
    const category = new AmenityCategory({
      id: uuid(),
      title: this.state.categoryTitle
    })
    try {
      const response = await repository.createAmenityCategory(category)
      amenityStore.addCategory(response)
      this.setState({ newAmenityCategoryOpen: false, categoryTitle: '', category: response }, () => {
        this.update();
        notifier.success('Category was successfuly created');
      })
    } catch (e) {
      notifier.failure('Unable to create category')
    }
  }

  deleteAmenity = async (amenity: Amenity) => {
    try {
      await repository.deleteAmenity(amenity)
      amenityStore.delete(amenity)
      notifier.success('Amenity was successfuly removed')
    } catch (e) {
      console.error(e);
      notifier.failure('Unable to delete amenity')
    }
  }

  deleteCategory = async (category: AmenityCategory) => {
    try {
      await repository.deleteAmenityCategory(category)
      await amenityStore.deleteCategory(category)
      this.update()
      const def = this.state.amenityCategories.find(c => c.title === 'Default')
      if (def) {
        this.setState({
          category: def,
          deleteCategoryOpen: false
        }, () => {
          notifier.success('Category was successfuly removed')
        })
      }
    } catch (e) {
      console.error(e);
      notifier.failure('Unable to delete category')
    }
  }

  toggleAmenityState = (amenity: Amenity) => {
    const nodeState = Object.assign({}, this.state.nodeState);
    nodeState[amenity.id] = !nodeState[amenity.id]
    this.setState({ nodeState })
  }

  renderAmenity = (amenity: Amenity) => {
    return <div
      className="amenity-editor-amenity">
      <div className="amenity-editor-image">
        { amenity.exists && <img alt={amenity.title} src={amenity.icon}/> }
        {/* { !amenity.exists && <Icon color="#fff" iconSize={72} icon="plus" style={{ marginTop: 10, marginBottom: 4, backgroundColor: '#08c'}} /> } */}
      </div>
      <div className="amenity-editor-title">{ amenity.title }</div>
    </div>
  }

  renderAmenities = () => {
    if (!this.state.category.exists) {
      return <h4 style={{ color: Colors.GRAY3, marginTop: 10 }}>Select category</h4>
    }

    const amenities = [...(this.state.nodes[this.state.category.id] || [])]
    if (!amenities) {
      return null
    }

    const grid = []
    const perRow = 4
    const rows = Math.floor(amenities.length / perRow) + 1
    for (let row = 0; row < rows; row++) {
      grid.push(amenities.slice(row * perRow, row * perRow + perRow))
    }
    return <div>
      <div className="amenities-icons-browser">
        { grid.map((row, rowIndex) => <Row key={`amenities-row-${rowIndex}`} flex={1}>
          { row.map((amenity, colIndex) => <Column key={`amenities-row-${rowIndex}-${colIndex}`} flex={0} style={{ alignContent: 'flex-start' }}>
            <AmenityPopover
              amenity={amenity}
              onSave={this.updateAmenity}
              onDelete={this.deleteAmenity}>
              <div
                className="amenity-editor-amenity">
                <div className="amenity-editor-image">
                  { amenity.exists && <img alt={amenity.title} src={amenity.icon}/> }
                  { !amenity.exists &&
                    <Icon
                      color="#fff"
                      iconSize={72}
                      icon="plus"
                      style={{ marginTop: 10, marginBottom: 4, backgroundColor: '#08c'}}
                    /> }
                </div>
                <div className="amenity-editor-title">{ amenity.title }</div>
              </div>
            </AmenityPopover>
          </Column>)}
        </Row>) }
      </div>
    </div>
  }

  render() {
    return <Dialog
      shouldReturnFocusOnClose={true}
      autoFocus={false}
      className="amenity-editor"
      isOpen={this.props.isOpen}
      onClose={this.props.onClose}
      onOpened={this.onOpened}
      style={{ width: 857, height: 670 }}
      title="Amenity Manager">
      <div className={Classes.DIALOG_BODY}>
        <Navbar>
          <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>Category</NavbarHeading>
            <NavbarDivider />
            <CategorySelect
              filterable={false}
              onItemSelect={category => this.setState({ category })}
              items={this.state.amenityCategories}
              itemRenderer={(item, options) =>
                <MenuItem
                  key={`category-select-${item.id}`}
                  text={item.title}
                  onClick={options.handleClick}
                />
              }>
              <Button
                className={Classes.MINIMAL}
                text={this.state.category.exists ? this.state.category.title : 'Select Category'}
                style={{ marginRight: 20 }}
              />
            </CategorySelect>
            <Popover2 isOpen={this.state.deleteCategoryOpen} content={
              <Button text="Confirm delete" intent="danger" icon="info-sign" onClick={() => this.deleteCategory(this.state.category)}/>
            }>
              <Button
                icon="trash"
                minimal
                disabled={this.state.category.title === 'Default'}
                onClick={() => this.setState({ deleteCategoryOpen: true })}
              />
            </Popover2>
          </NavbarGroup>
          <NavbarGroup align={Alignment.RIGHT}>
            <Popover2 isOpen={this.state.newAmenityCategoryOpen} content={
              <Row flex={1}>
                <InputGroup
                  fill
                  style={{ width: '100%' }}
                  placeholder="Enter category title"
                  value={this.state.categoryTitle}
                  onChange={(e: any) => this.setState({
                    categoryTitle: e.currentTarget.value
                  })}
                />
                <Button icon="tick" intent="success" onClick={() => this.createCategory() }/>
                <Button icon="cross" intent="danger" minimal  onClick={() => this.setState({ newAmenityCategoryOpen: false })} />
              </Row>
            }>
              <Button icon="plus" text="Add Category" style={{ marginRight: 20 }} onClick={() => this.setState({ newAmenityCategoryOpen: true })} />
            </Popover2>

            <AmenityPopover
              amenity={new Amenity({})}
              onSave={this.createAmenity}
              onDelete={() => {}}>
              <Button icon="plus" text="Add Amenity" intent="primary"/>
            </AmenityPopover>
          </NavbarGroup>
        </Navbar>
        <Row flex={1}>
          <Column flex={3} className="amenity-editor-icons">
            { !this.state.selected && this.renderAmenities() }
          </Column>
        </Row>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="cross"
            text="Close"
            onClick={this.props.onClose}
          />
        </div>
      </div>
    </Dialog>
  }
}
