import React from 'react'
import { FormGroup, Popover, PopoverInteractionKind, InputGroup } from '@blueprintjs/core'
import { SketchPicker } from 'react-color'
import HelpLabel from '../../help_label'
import { kebabToCamel } from '../../../../../common'
import { Props } from './props'

export default function ColorEditor(props : Props) {
  return <div className="edit-block">
    <FormGroup
      className="with-help"
      label={<HelpLabel
        label={props.label}
        description={props.description}
        defaults={props.defaults}
        requirement={props.requirement}
      />}
      inline={true}>
      <Popover interactionKind={PopoverInteractionKind.CLICK}>
        <InputGroup
          disabled={typeof props.value === 'object'}
          style={{ border: `1px solid ${props.value}`}}
          defaultValue={props.value}
        />
        {typeof props.value !== 'object' && <SketchPicker
          color={props.value}
          onChangeComplete={(color: any) => props.setValue(kebabToCamel(props.property), props.propertyType, color.hex) }
        />}
      </Popover>
    </FormGroup>
  </div>
}
