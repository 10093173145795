import React from 'react'
import { Button, Checkbox, FormGroup, InputGroup, NumericInput, MenuItem, Switch } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { Column } from '../../flex'
import Translator from '../translator'
import AmenityPicker from '../amenity_picker'
import FloorSelection from '../../map/floor_selection'
import PlaceSelection from '../../map/place_selection'
import Amenity from '../../../models/amenity'
import Feature from '../../../models/feature'
import { LANGUAGES } from '../../../models/language'
import Place from '../../../models/place'
import Floor from '../../../models/floor'
import AmenityCategory from '../../../models/amenity_category'
import MetadataEditor from '../metadata_editor'
import JSONEditor from '../json_editor'
import Media from '../media'
import PoiType, { POI_TYPE } from '../../../models/poi_type'
import POI_TYPES from '../../../poi_types'
import { defaultIcon } from '../../../icons'
import { NodeState } from '../../../types/node_state'
import MapContext, { MapContextInterface } from '../../../map_context'
import { debug, capitalize } from '../../../common'
import deepEqual from 'deep-equal'
import WorkingHours from '../working_hours'
import Notifier from '../../../notifier'
import EventEditor from '../event_editor';
import {TagEditor} from "../tag_editor";
import {SpellingsEditor} from "../spellings_editor";
import MultiAmenityPicker from "../multi_amenity_picker";

type Direction = 'both' | 'up' | 'down';

const PoiTypeSelect = Select.ofType<PoiType>();
const DirectionSelect = Select.ofType<Direction>();

interface Props {
  amenities: Array<Amenity>
  amenityCategories: Array<AmenityCategory>
  point: Feature
  places: Array<Place>
  floors: Array<Floor>
  onChange: (feature: Feature) => void
}

interface State {
  amenity: Amenity
  nodeState: NodeState
}

export default class EditPoint extends React.Component<Props, State> {
  static contextType = MapContext

  constructor(props: Props) {
    super(props)
    this.state = {
      amenity: this.getAmenity(),
      nodeState: {}
    };
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // console.log('edit point prevProps', prevProps, 'props', this.props, deepEqual(this.props, prevProps));
    if (!deepEqual(this.props, prevProps)) {
      // console.log('edit point props change', this.props, prevProps);
    }

    if (!deepEqual(this.state, prevState)) {
      // console.log('edit point state change', this.state, prevState);
    }

    if (this.props.point.properties.amenity.id !== this.state.amenity.id && this.state.amenity.id !== prevState.amenity.id) {
      this.setState({ amenity: this.getAmenity( )})
    }
  }

  getFloor(feature: Feature) {
    const floor = this.props.floors.find(p => p.id === feature.properties.floor_id)
    if (!floor) {
      return new Floor({})
    }
    return floor
  }

  getPlace(feature: Feature) {
    const place = this.props.places.find(p => p.id === feature.properties.place_id)
    if (!place) {
      return new Place({})
    }
    return place
  }

  getAmenity() {
    const amenity = this.props.amenities.find(amenity => amenity.id === this.props.point.properties.amenity)
    return amenity || new Amenity({})
  }

  getIcon() {
    const poiType = this.getType()
    if (!poiType) {
      return
    }

    if (poiType.type === POI_TYPE.POI && this.state.amenity.exists) {
      return <img alt={this.state.amenity.title} className="editor-amenity" src={this.state.amenity.icon} />
    } else {
      return <img alt={poiType.title} className="editor-amenity" src={poiType.icon} />
    }
  }

  getType() {
    let type = POI_TYPES.find(poiType => poiType.type === this.props.point.properties.type)
    if (!type) {
      type = POI_TYPES.find(poiType => poiType.type === POI_TYPE.POI)
    }

    return type
  }

  poiTypeRenderer(poiType: PoiType, options: any) {
    return <MenuItem
      key={poiType.type}
      text={poiType.title}
      onClick={options.handleClick}
    />
  }

  // priorityRenderer(priority: Priority, options: any) {
  //   return <MenuItem
  //     key={`priority-${priority.id}`}
  //     text={priority.title}
  //     onClick={options.handleClick}
  //   />
  // }

  onGroupChange = (e: any) => {
    const feature = new Feature(this.props.point.json)
    feature.properties.group = e.currentTarget.value
    this.props.onChange(feature)
  }

  onURLChange = (e: any) => {
    const feature = new Feature(this.props.point.json)
    feature.properties.url = e.currentTarget.value
    this.props.onChange(feature)
  }

  onContactChange = (e: any) => {
    const feature = new Feature(this.props.point.json)
    feature.properties.contact = e.currentTarget.value
    this.props.onChange(feature)
  }

  onRemoteIdChange = (e: any) => {
    const feature = new Feature(this.props.point.json)
    feature.properties.remote_id = e.currentTarget.value
    this.props.onChange(feature)
  }

  onRangeChange = (valueAsNumber: number, valueAsString: string) => {
    const feature = new Feature(this.props.point.json)
    feature.properties.range = valueAsNumber
    this.props.onChange(feature)
  }

  render() {
    let poiType = POI_TYPES.find(poiType => poiType.type === this.props.point.properties.type)
    if (!poiType) {
      poiType = new PoiType("poi", "Default", defaultIcon)
    }

    let copy_id = this.props.point.id.split(':');
    let id = copy_id.length === 2 ? copy_id[1] : this.props.point.id;
    console.log('id:', id);
    return <Column flex={1}>
      <h6 className="bp3-heading edit-heading">
        <div style={{width: 44, float: 'left'}}>
          { this.getIcon() }
        </div>
        <Column flex={1}>
          <div style={{ fontSize: 12, fontWeight: 'bold'}}>{ poiType && poiType.title }</div>
          <div style={{ fontSize: 16 }}>{ this.props.point.properties.title }</div>
          <div style={{ fontSize: 10 }}>
            lat { this.props.point.geometry.coordinates[1].toFixed(8) }
            &nbsp;
            lng { this.props.point.geometry.coordinates[0].toFixed(8) }
            &nbsp;
            level { this.props.point.properties.level }
          </div>
          <div style={{fontSize: 10}}>
            <Button
              small={true}
              className="very-small-btn"
              text="copy id"
              data-id={id}
              intent="primary"
              outlined
              onClick={() => {
                const input = (document.querySelector('#poi-id-input') as HTMLInputElement);
                input.select();
                if (document.execCommand) {
                  document.execCommand('copy');
                  input.setSelectionRange(0, 0)
                  Notifier.success('Feature id has been copied into clipboard EXEC')
                } else {
                  navigator.clipboard.writeText(id[1]).then(() => {
                    Notifier.success('Feature id has been copied into clipboard')
                  });
                }
              }}
            />

            <input className="input-hollow" id="poi-id-input" value={id}/>
          </div>
        </Column>
      </h6>

      <Column flex={1} className="edit-block">
        <PlaceSelection
          simple={false}
          selected={this.getPlace(this.props.point)}
          onSelect={(place) => {
            const point = new Feature(this.props.point.json)
            point.properties.place_id = place.id
            this.props.onChange(point)
          }}
        />
      </Column>

      <Column flex={1} className="edit-block">
        { this.props.point.isLevelChanger && <FormGroup
          label="Floors"
          inline={true}>
            { this.props.floors.map(floor =>
              <Checkbox
                key={`floor-checkbox-${floor.id}`}
                checked={this.props.point.properties.levels ? this.props.point.properties.levels.includes(floor.level) : false}
                onChange={(evt) => {
                  const feature = new Feature(this.props.point.json)
                  if (typeof feature.properties.levels === 'undefined') {
                    feature.properties.levels = []
                  }
                  let included = feature.properties.levels.includes(floor.level)

                  if (evt.currentTarget.checked && !included) {
                    feature.properties.levels.push(floor.level)
                  } else {
                    feature.properties.levels = feature.properties.levels.filter((l: number) => l !== floor.level)
                  }

                  feature.properties[`__level_${floor.level}`] = feature.properties.levels.includes(floor.level)
                  this.props.onChange(feature)
                }}>
              { floor.name }
              </Checkbox>
            )}
        </FormGroup> }
        { !this.props.point.isLevelChanger &&
          <FloorSelection
            simple={false}
            selected={this.getFloor(this.props.point)}
            onSelect={(floor) => {
              const point = new Feature(this.props.point.json)
              point.properties.floor_id = floor.id
              point.properties.level = floor.level
              this.props.onChange(point)
            }}
          />
        }
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Type"
          inline={true}>
          <PoiTypeSelect
            items={POI_TYPES}
            itemRenderer={this.poiTypeRenderer}
            activeItem={poiType}
            onItemSelect={(poiType) => {
              const point = new Feature(this.props.point.json)
              const level = (this.context as MapContextInterface).floor.level

              point.properties.type = poiType.type
              if (point.isLevelChanger && typeof point.properties.levels === 'undefined') {
                point.properties.levels = [ level ]
                point.properties[`__level_${level}`] = true
              }

              if (!point.isLevelChanger && Array.isArray(point.properties.levels)) {
                point.properties.level = level;
                delete point.properties.levels
              }

              if (point.isConnector) {
                point.properties.amenity = 'outdoor_connector';
                point.properties.level = level;
                point.properties[`__level_${level}`] = true
              }

              debug.log('on feature type switch', point)

              this.props.onChange(point)
            }}>
            <Button text={poiType ? poiType.title : 'Choose Type'}
                    minimal={true}
                    rightIcon="double-caret-vertical"/>
          </PoiTypeSelect>
        </FormGroup>
      </Column>

      { this.props.point.properties.type !== 'outdoor_connector' && <AmenityPicker
        feature={this.props.point}
        amenities={this.props.amenities}
        amenityCategories={this.props.amenityCategories}
        onChange={this.props.onChange}
      /> }

      { this.props.point.properties.type !== 'outdoor_connector' && <MultiAmenityPicker
          feature={this.props.point}
          amenities={this.props.amenities}
          amenityCategories={this.props.amenityCategories}
          onChange={this.props.onChange}
      /> }

      { this.props.point.properties.type === 'escalator' && <Column flex={1} className="edit-block">
        <FormGroup label="Direction" inline={true}>
          <DirectionSelect
            activeItem={this.props.point.properties.direction || 'both'}
            items={['up', 'down', 'both']}
            itemRenderer={(item, options) => <MenuItem
              text={capitalize(item)}
              onClick={options.handleClick}
            />}
            onItemSelect={direction => {
              const feature = new Feature(this.props.point.json)
              feature.properties.direction = direction
              this.props.onChange(feature)
            }}
          ><Button minimal text={capitalize(this.props.point.properties.direction || 'both')} rightIcon="chevron-down"/>
          </DirectionSelect>
        </FormGroup>
      </Column> }

      { this.props.point.isLevelChanger && <Column flex={1} className="edit-block">
        <FormGroup
          label="Group"
          inline={true}>
            <InputGroup
              value={this.props.point.properties.group || ''}
              onChange={this.onGroupChange}
            />
        </FormGroup>
      </Column> }

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Available"
          inline={true}>
            <Switch
              checked={typeof this.props.point.properties.available === 'undefined' ? true : this.props.point.properties.available}
              onChange={(e) => {
                const feature = new Feature(this.props.point.json);
                feature.properties.available = e.currentTarget.checked;
                this.props.onChange(feature)
              }}
            />
        </FormGroup>
      </Column>

      <Translator
        area={false}
        feature={this.props.point}
        field="title_i18n"
        fieldName="Title"
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      <Translator
        area={true}
        feature={this.props.point}
        field="description_i18n"
        fieldName="Description"
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      <Column flex={1} className="edit-block">
        <FormGroup
          label="URL"
          inline={true}>
            <InputGroup
              value={this.props.point.properties.url || ''}
              onChange={this.onURLChange}
            />
        </FormGroup>
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Contact"
          inline={true}>
          <InputGroup
            value={this.props.point.properties.contact || ''}
            onChange={this.onContactChange}
          />
        </FormGroup>
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Remote ID"
          inline={true}>
          <InputGroup
            value={this.props.point.properties.remote_id || ''}
            onChange={this.onRemoteIdChange}
          />
        </FormGroup>
      </Column>

      <Media feature={this.props.point} onChange={this.props.onChange}/>

      <WorkingHours feature={this.props.point} onChange={(_feature) => {
        const feature = new Feature(this.props.point.json);
        feature.properties.workingHours = _feature.properties.workingHours;
        this.props.onChange(feature);
      }}/>

      <EventEditor
        feature={this.props.point}
        onChange={(since: Date | null, till: Date | null) => {
          const feature = new Feature(this.props.point.json);
          if (typeof feature.properties.metadata !== 'object') {
            feature.properties.metadata = {}
          }

          if (since) {
            feature.properties.metadata.dateStart = since.getTime();
          } else {
            delete feature.properties.metadata.dateStart;
          }

          if (till) {
            feature.properties.metadata.dateEnd = till.getTime();
          } else {
            delete feature.properties.metadata.dateEnd;
          }

          this.props.onChange(feature);
        }}
      />

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Icon Only"
          inline={true}>
            <Switch
              checked={this.props.point.properties.icon_only || false}
              onChange={(e) => {
                const feature = new Feature(this.props.point.json)
                feature.properties.icon_only = e.currentTarget.checked
                if (e.currentTarget.checked && feature.properties.text_only) {
                  feature.properties.text_only = false
                }
                this.props.onChange(feature)
              }}
            />
        </FormGroup>
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Text Only"
          inline={true}>
            <Switch
              checked={this.props.point.properties.text_only || false}
              onChange={(e) => {
                const feature = new Feature(this.props.point.json)
                feature.properties.text_only = e.currentTarget.checked
                if (e.currentTarget.checked && feature.properties.icon_only) {
                  feature.properties.icon_only = false
                }
                this.props.onChange(feature)
              }}
            />
        </FormGroup>
      </Column>

      <TagEditor
        label="Keywords"
        point={this.props.point}
        field="keywords"
        onChange={(feature) => {
          this.props.onChange(feature)
        }}
      />

      <SpellingsEditor
        label="Misspellings"
        point={this.props.point}
        field="spelling"
        onChange={(feature) => {
          this.props.onChange(feature)
        }}
      />

      <MetadataEditor
        feature={this.props.point}
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      {/* <Column flex={1} className="edit-block">
        <FormGroup
          label="Priority"
          inline={true}>
          <PrioritySelect
            items={Priorities}
            itemRenderer={this.priorityRenderer}
            activeItem={this.props.point.properties.priority || Priorities[0].id}
            onItemSelect={(priority) => {
              const point = new Feature(this.props.point.json)
              point.properties.priority = priority.id
              this.props.onChange(point)
            }}>
            <Button text={this.props.point.properties.priority ? (Priorities.find(p => p.id === this.props.point.properties.priority) as Priority).title : Priorities[0].title}
                    minimal={true}
                    rightIcon="double-caret-vertical"/>
          </PrioritySelect>
        </FormGroup>
      </Column> */}

      { this.props.point.properties.type !== 'outdoor_connector' && <Column flex={1} className="edit-block">
        <FormGroup label="Range (m)" inline={true}>
          <NumericInput
            buttonPosition="none"
            value={this.props.point.properties.range}
            onValueChange={this.onRangeChange}/>
        </FormGroup>
      </Column> }



      <JSONEditor
        readonly={false}
        label="General Metadata"
        id={this.props.point.properties.id}
        value={this.props.point.properties.metadata || "{}"}
        onChange={(value) => {
          const feature = new Feature(this.props.point.json)
          feature.properties.metadata = value
          this.props.onChange(feature)
        }}
      />

      <JSONEditor
        readonly={true}
        label="Raw GeoJSON"
        id={`${this.props.point.properties.id}-raw`}
        value={JSON.stringify(this.props.point, null, 2)}
        onChange={(value) => {
          try {
            const data = JSON.parse(value)
            const feature = new Feature(data)
            this.props.onChange(feature)
          } catch (e) {
            console.error('Raw JSON Editor Format ERROR', e)
          }
        }}
      />
    </Column>
  }
}
