import { FunctionComponent } from 'react'
import { Props } from './props'

const DefaultStyle = {}

export const Row: FunctionComponent<Props> = ({ style, className, flex, children }) => {
  const _style = style ? {...DefaultStyle, style, flex} : {...DefaultStyle, flex}
  const _className = className ? `flex-row ${className}` : 'flex-row'
  return <div className={_className} style={_style}>{ children }</div>
}

export default Row