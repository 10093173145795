import DataSource from './data_source'
import Feature, { FeatureCollection } from '../models/feature'
import Routing from '../routing'

type ChangeContainer = {
  action: string
  feature: Feature
}

export type RouteOptions = {
  avoidElevators: false;
  avoidEscalators: false;
  avoidStaircases: false;
  avoidRamps: false;
  avoidNarrowPaths: false;
  avoidRevolvingDoors: false;
  avoidTicketGates: false;
  avoidBarriers: false;
  avoidHills: false;
  pathFixDistance: number;
  lang: string;
}

export default class RoutingSource extends DataSource {
  isEditable = false
  start?: Feature
  finish?: Feature
  lines?: Feature[]
  changes: ChangeContainer[]
  route: any
  routing: Routing
  options: RouteOptions = {
    avoidElevators: false,
    avoidEscalators: false,
    avoidStaircases: false,
    avoidRamps: false,
    avoidNarrowPaths: false,
    avoidRevolvingDoors: false,
    avoidTicketGates: false,
    avoidBarriers: false,
    avoidHills: false,
    pathFixDistance: 1000,
    lang: 'en'
  }
  date: Date | null;

  constructor() {
    super('route');
    this.changes = [];
    this.routing = new Routing();
    this.date = null;
  }

  setDateFilter(date: Date | null) {
    this.date = date;
    if (this.start && this.finish) {
      this.update(this.start, this.finish)
    }
  }

  async updateOptions(options: RouteOptions) {
    this.options = Object.assign(this.options, options);
  }

  async update(start?: Feature, finish?: Feature) {
    this.start = start
    this.finish = finish

    this.data = new FeatureCollection({features: [this.start, this.finish ].concat(this.lines || []).filter(i => i)})
    this.notify('feature-updated')

    if (start && finish) {
      this.notify('loading-start')
      const [ levelPaths, data ] = await this.routing.route(start, finish, this.options, this.date?.getTime() ?? 0)
      if (levelPaths) {
        const lines = [] as Feature[]
        const levels = Object.keys(levelPaths)
        levels.forEach(level => {
          const path = levelPaths[level] as Feature
          path.id = `routing-path-${level}`
          path.properties.amenity = 'chevron_right'
          path.properties.level = level
          lines.push(path)
        })
        this.lines = lines
      } else {
        this.lines = []
      }

      this.data = new FeatureCollection({
        features: [ this.start, this.finish ].concat(this.lines || []).filter(i => i),
      })
      this.data.properties = data;
      this.notify('loading-finished')
      this.notify('feature-updated')
    }
  }

  cancel() {
    this.start = undefined
    this.finish = undefined
    this.lines = undefined
    this.route = undefined
    this.data = new FeatureCollection({features: [ this.start, this.finish ].concat(this.lines || []).filter(i => i)})
    this.notify('feature-updated')
  }
}