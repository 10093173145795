import Observable from "./observable"

export type BusEvent = {
  [id: string]: string
}

export class EventBus extends Observable {
  send(message: string) {
    this.notify('message', message)
  }

  toggle(prop: string, value: boolean) {
    this.notify('toggle', { prop, value })
  }
}

const instance = new EventBus()
export default instance
