import { Button, Checkbox, Dialog, FormGroup, MenuItem } from '@blueprintjs/core';
import GeoJSONSource from '../sources/geojson_source';
import React, { useState, useEffect, MouseEvent } from 'react';
import { DIALOG_BODY, DIALOG_FOOTER, DIALOG_FOOTER_ACTIONS } from '@blueprintjs/core/lib/esm/common/classes';
import { Row } from './flex'
import { Select } from '@blueprintjs/select';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  geojsonSource: GeoJSONSource
}

const ValueSelect = Select.ofType<string | number | boolean>()

export function FilterDialog({ isOpen, geojsonSource, onClose} :Props) {
  const [ properties, setProperties ] = useState<{ [id: string]: (string | number | boolean)[] }>({});
  const [ selected, setSelected ] = useState<{ [id: string]: string | number | boolean}>({});

  useEffect(() => {
    const properties = geojsonSource.getProperties();
    setProperties(properties)
  }, [ geojsonSource ]);

  const filter = () => {
    geojsonSource.setMetadataFilter(selected);
    onClose();
  }

  const clear = () => {
    setSelected({});
    geojsonSource.setMetadataFilter({});
    onClose();
  }

  const onSelect = (item: string | number | boolean, key: string) => {
    const values = {...selected};
    
    if (item === 'No Selection') {
      delete values[key]
    } else {
      values[key] = item;
    }

    setSelected(values)
  }

  const itemRenderer = (item: string | number | boolean, options: any) => {
    return <MenuItem
      key={`menu-item-${item}`}
      text={item}
      onClick={options.handleClick}
    />
  }

  const renderProperties = () => {
    return Object.keys(properties).map(key => {
      const value = typeof selected[key] === 'undefined' ? 'No selection' : selected[key];
      return <Row flex={1} className='property-row' key={`property-${key}`}>
        <FormGroup label={key} inline={true}>
          <ValueSelect
            items={['No Selection', ...properties[key]]}
            itemRenderer={(item, options) => itemRenderer(item, options)}
            onItemSelect={item => onSelect(item, key)}>
              <Button text={value} rightIcon="chevron-down"/>
          </ValueSelect>
        </FormGroup>
      </Row>
    })
  }

  const onExhibitchange = (evt: MouseEvent<HTMLInputElement>) => {
    geojsonSource.includeNonExhibits = evt.currentTarget.checked;
    geojsonSource.runFilter();
  }

  return (
    <Dialog title="Feature Filter" isOpen={isOpen} onClose={onClose}>
      <div className={DIALOG_BODY}>
        { renderProperties() }

        <div className="">
          <FormGroup label={"Include Features without any exhibition set"} inline={true}>
            <Checkbox checked={geojsonSource.includeNonExhibits} onChange={onExhibitchange}/>
          </FormGroup>
        </div>
      </div>
      <div className={`${DIALOG_FOOTER} ${DIALOG_FOOTER_ACTIONS} flex-row`}>
        <div className="f-1">
          <Button text="Close" icon="cross" onClick={onClose} minimal/>
        </div>
        <div className="flex-row f-1 jc-e">
          <Button intent="none" text="Cancel filters" icon="clean" onClick={clear} className='m-r-24'/>
          <Button intent="success" text="Filter" icon="filter" onClick={filter}/>
        </div>
      </div>
    </Dialog>
  );
}
