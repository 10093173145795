import Observable from "./observable"

export class Notifier extends Observable {
  success(message: string) {
    this.notify('success', message)
  }

  failure(message: string) {
    this.notify('failure', message)
  }
}

const instance = new Notifier()
export default instance
