// import Wayfinding from './wayfinding'
import Feature, { FeatureCollection } from './models/feature'
import { lineString } from '@turf/helpers'
import { axios, debug } from './common'
import Observable from './observable'
import { RouteOptions } from './sources/routing_source'

export default class Routing extends Observable {
  data: FeatureCollection

  constructor() {
    super();
    this.data = new FeatureCollection({});
  }

  setData(collection: FeatureCollection) {
    this.data = collection
  }

  async route(start: Feature, finish: Feature, options: RouteOptions, date: number) {
    debug.log('routing with start', start, 'finish', finish)

    const query = [
      `startLng=${start.geometry.coordinates[0]}`,
      `startLat=${start.geometry.coordinates[1]}`,
      `startLevel=${start.properties.level}`,
      `endLng=${finish.geometry.coordinates[0]}`,
      `endLat=${finish.geometry.coordinates[1]}`,
      `endLevel=${finish.properties.level}`,
      `date=${date}`
    ].join('&');

    const { data } = await axios.post(`/routing?${query}`, options);

    if (!data.route) {
      return []
    }

    const levelPoints = {} as any
    data.route.forEach((point: any) => {
      if (typeof levelPoints[point.properties.level] === 'undefined') {
        levelPoints[point.properties.level] = []
      }
      levelPoints[point.properties.level].push(point)
    })

    const levels = Object.keys(levelPoints)
    const levelPaths = {} as any
    levels.forEach(level => {
      levelPaths[level] = new Feature(lineString(levelPoints[level].map((point: any) => point.geometry.coordinates)))
    })
    return [ levelPaths, data ]
  }
}